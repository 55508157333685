import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import {
  ApplicationSettings,
  CreateSiteHardwareConfigurationInput,
  CreateSiteHardwareConfigurationMutation,
  DeleteSiteHardwareConfigurationInput,
  DeleteSiteHardwareConfigurationMutation,
  HardwareDevice,
  ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery,
  SiteHardwareConfiguration,
  UpdateSiteHardwareConfigurationInput,
  UpdateSiteHardwareConfigurationMutation,
} from 'src/API';
import { auditDecorator } from '../utils';
import {
  createSiteHardwareConfiguration as createSiteHardwareConfigurationMutation,
  updateSiteHardwareConfiguration as updateSiteHardwareConfigurationMutation,
  deleteSiteHardwareConfiguration as deleteSiteHardwareConfigurationMutation,
} from 'src/graphql/mutations';
import { debug } from 'src/utils';
import { listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName } from 'src/graphql/queries';

export let querySiteHardwareConfigurations = async (siteCode: string, status: string = '', versionId: string = ''): Promise<SiteHardwareConfiguration[] | []> => {
  debug(`querySiteHardwareConfigurations() siteCode is ${siteCode} status is ${status} versionId is ${versionId}`);
 
  let siteHardwareConfigurations: SiteHardwareConfiguration[] = [];
  
  try {
    const response = await API.graphql(graphqlOperation(listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName,
      {
        siteCode: siteCode,
        statusVersionId: {
          beginsWith: {
            status: `${status}`,
            versionId: `${versionId}`,
          }
        }
      })) as GraphQLResult<ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery>;
    if (response.data && response.data.listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName) {
      siteHardwareConfigurations = response.data.listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName.items as SiteHardwareConfiguration[];
    }
    debug(`response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`querySiteHardwareConfigurations() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(siteHardwareConfigurations);
};    
querySiteHardwareConfigurations = auditDecorator('querySiteHardwareConfigurations', querySiteHardwareConfigurations);

export let createSiteHardwareConfiguration = async (input: CreateSiteHardwareConfigurationInput): Promise<SiteHardwareConfiguration | null> => {
  debug(`createSiteHardwareConfiguration() input is ${JSON.stringify(input)}`);
 
  let createdSiteHardwareConfiguration: SiteHardwareConfiguration | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(createSiteHardwareConfigurationMutation,
      {
        input
      })) as GraphQLResult<CreateSiteHardwareConfigurationMutation>;
    if (response.data && response.data.createSiteHardwareConfiguration) {
      createdSiteHardwareConfiguration = response.data.createSiteHardwareConfiguration as SiteHardwareConfiguration;
    }
    debug(`createSiteHardwareConfiguration() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`createSiteHardwareConfiguration() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(createdSiteHardwareConfiguration);
};    
createSiteHardwareConfiguration = auditDecorator('createSiteHardwareConfiguration', createSiteHardwareConfiguration);

export let updateSiteHardwareConfiguration = async (input: UpdateSiteHardwareConfigurationInput): Promise<SiteHardwareConfiguration | null> => {
  debug(`updateSiteHardwareConfiguration() input is ${JSON.stringify(input)}`);
 
  let updatedSiteHardwareConfiguration: SiteHardwareConfiguration | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(updateSiteHardwareConfigurationMutation,
      {
        input
      })) as GraphQLResult<UpdateSiteHardwareConfigurationMutation>;
    if (response.data && response.data.updateSiteHardwareConfiguration) {
      updatedSiteHardwareConfiguration = response.data.updateSiteHardwareConfiguration as SiteHardwareConfiguration;
    }
    debug(`updateSiteHardwareConfiguration() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`updateSiteHardwareConfiguration() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(updatedSiteHardwareConfiguration);
};    
updateSiteHardwareConfiguration = auditDecorator('updateSiteHardwareConfiguration', updateSiteHardwareConfiguration);

export let deleteSiteHardwareConfiguration = async (input: DeleteSiteHardwareConfigurationInput): Promise<SiteHardwareConfiguration | null> => {
  debug(`deleteSiteHardwareConfiguration() input is ${JSON.stringify(input)}`);
 
  let deletedSiteHardwareConfiguration: SiteHardwareConfiguration | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(deleteSiteHardwareConfigurationMutation,
      {
        input
      })) as GraphQLResult<DeleteSiteHardwareConfigurationMutation>;
    if (response.data && response.data.deleteSiteHardwareConfiguration) {
      deletedSiteHardwareConfiguration = response.data.deleteSiteHardwareConfiguration as SiteHardwareConfiguration;
    }
    debug(`deleteSiteHardwareConfiguration() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`deleteSiteHardwareConfiguration() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(deletedSiteHardwareConfiguration);
};    
deleteSiteHardwareConfiguration = auditDecorator('deleteSiteHardwareConfiguration', deleteSiteHardwareConfiguration);

export function getApplicationSettingsForHardwareTypeAndDeviceName(
  applicationSettings: ApplicationSettings,
  hardwareTypeName: string,
  hardwareDeviceName: string): HardwareDevice | null
{
  debug(`getApplicationSettingsForHardwareTypeAndDeviceName() applicationSettings is ${JSON.stringify(applicationSettings)} \
  hardwareTypeName is ${hardwareTypeName} hardwareDeviceName is ${hardwareDeviceName}`);

  let hardwareDevice: HardwareDevice | null = null;

  try {
    hardwareDevice = applicationSettings?.hardwareTypes
      ?.find((ht) => ht?.name === hardwareTypeName)?.hardwareDevices
      ?.find((hd) => hd?.name === hardwareDeviceName) || null;
  } catch (error) {
    console.error(error); 
    throw error;
  }

  return hardwareDevice;
}
