export enum ComponentType {
  button = 'button',
  input = 'input',
  link = 'link',
  toggle = 'toggle',
}

export enum Page {
  Documents = 'documents',
  Templates = 'templates',
  Reviews = 'reviews',
}

interface IPage {
  admin?: boolean;
  code: Page;
  name: string;
  order: number;
  params?: string[],
  route: string;
}

export const Pages: IPage[] = [
  {
    code: Page.Documents,
    name: 'documents',
    order: 2,
    params: [
      'siteCode',
    ],
    route: '/documents/',
  },
  {
    code: Page.Reviews,
    name: 'reviews',
    order: 3,
    route: '/reviews/',
  },
  {
    code: Page.Templates,
    name: 'templates',
    order: 1,
    route: '/templates/',
  },
];

export enum QueryKey {
  allApplicationSettings = 'allApplicationSettings',
  selectedApplicationSettings = 'selectedApplicationSettings',
  siteHardwareConfigurations = 'siteHardwareConfigurations',
  userPreferences = 'userPreferences',
}

export enum UIAttribute {
  componentType = 'componentType',
  displayOnly = 'displayOnly',
  label = 'label',
  sequence = 'sequence',
}

export const DEFAULT_USER_PREFERENCES = {
  darkMode: false,
  language: {
    label: 'English',
    value: 'en-US',
  },
}