/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const GetSesamePermissionsByPersonID = /* GraphQL */ `query GetSesamePermissionsByPersonID($method: String!, $personID: String!) {
  GetSesamePermissionsByPersonID(method: $method, personID: $personID)
}
` as GeneratedQuery<
  APITypes.GetSesamePermissionsByPersonIDQueryVariables,
  APITypes.GetSesamePermissionsByPersonIDQuery
>;
export const getUserPreferences = /* GraphQL */ `query GetUserPreferences($userId: String!) {
  getUserPreferences(userId: $userId) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetUserPreferencesQueryVariables,
  APITypes.GetUserPreferencesQuery
>;
export const listUserPreferences = /* GraphQL */ `query ListUserPreferences(
  $userId: String
  $filter: ModelUserPreferencesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserPreferences(
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      preferences
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserPreferencesQueryVariables,
  APITypes.ListUserPreferencesQuery
>;
export const getUserAction = /* GraphQL */ `query GetUserAction(
  $userId: String!
  $actionType: String!
  $actionTime: AWSTimestamp!
) {
  getUserAction(
    userId: $userId
    actionType: $actionType
    actionTime: $actionTime
  ) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetUserActionQueryVariables,
  APITypes.GetUserActionQuery
>;
export const listUserActions = /* GraphQL */ `query ListUserActions(
  $userId: String
  $actionTypeActionTime: ModelUserActionPrimaryCompositeKeyConditionInput
  $filter: ModelUserActionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserActions(
    userId: $userId
    actionTypeActionTime: $actionTypeActionTime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      actionType
      actionTime
      parameters
      ttl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserActionsQueryVariables,
  APITypes.ListUserActionsQuery
>;
export const listUserActionByActionTypeAndActionTimeAndUserId = /* GraphQL */ `query ListUserActionByActionTypeAndActionTimeAndUserId(
  $actionType: String!
  $actionTimeUserId: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserActionByActionTypeAndActionTimeAndUserId(
    actionType: $actionType
    actionTimeUserId: $actionTimeUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      actionType
      actionTime
      parameters
      ttl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserActionByActionTypeAndActionTimeAndUserIdQueryVariables,
  APITypes.ListUserActionByActionTypeAndActionTimeAndUserIdQuery
>;
export const listUserActionByActionTimeAndActionTypeAndUserId = /* GraphQL */ `query ListUserActionByActionTimeAndActionTypeAndUserId(
  $actionTime: AWSTimestamp!
  $actionTypeUserId: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserActionByActionTimeAndActionTypeAndUserId(
    actionTime: $actionTime
    actionTypeUserId: $actionTypeUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      actionType
      actionTime
      parameters
      ttl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserActionByActionTimeAndActionTypeAndUserIdQueryVariables,
  APITypes.ListUserActionByActionTimeAndActionTypeAndUserIdQuery
>;
export const getApplicationSettings = /* GraphQL */ `query GetApplicationSettings($versionId: String!) {
  getApplicationSettings(versionId: $versionId) {
    versionId
    hardwareTypes {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    slotTypes {
      name
      description
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetApplicationSettingsQueryVariables,
  APITypes.GetApplicationSettingsQuery
>;
export const listApplicationSettings = /* GraphQL */ `query ListApplicationSettings(
  $versionId: String
  $filter: ModelApplicationSettingsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listApplicationSettings(
    versionId: $versionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      versionId
      hardwareTypes {
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            uiAttributes {
              name
              value
            }
          }
          rules {
            name
            description
          }
          slots {
            name
            description
            type
          }
        }
        settings
      }
      slotTypes {
        name
        description
        settings
      }
      pacs {
        name
        description
        settings
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListApplicationSettingsQueryVariables,
  APITypes.ListApplicationSettingsQuery
>;
export const getHardwareTemplate = /* GraphQL */ `query GetHardwareTemplate($name: String!, $versionId: String!) {
  getHardwareTemplate(name: $name, versionId: $versionId) {
    name
    description
    status
    versionId
    hardwareType {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    hardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    deviceSettings {
      key
      value
      uiAttributes {
        name
        value
      }
    }
    boardSettings {
      columnSpan
      columnOffset
      rowSpan
      sequence
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetHardwareTemplateQueryVariables,
  APITypes.GetHardwareTemplateQuery
>;
export const listHardwareTemplates = /* GraphQL */ `query ListHardwareTemplates(
  $name: String
  $versionId: ModelStringKeyConditionInput
  $filter: ModelHardwareTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHardwareTemplates(
    name: $name
    versionId: $versionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      name
      description
      status
      versionId
      hardwareType {
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            uiAttributes {
              name
              value
            }
          }
          rules {
            name
            description
          }
          slots {
            name
            description
            type
          }
        }
        settings
      }
      hardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareTemplatesQueryVariables,
  APITypes.ListHardwareTemplatesQuery
>;
export const listHardwareTemplateByStatusAndNameAndVersionId = /* GraphQL */ `query ListHardwareTemplateByStatusAndNameAndVersionId(
  $status: Status!
  $nameVersionId: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHardwareTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listHardwareTemplateByStatusAndNameAndVersionId(
    status: $status
    nameVersionId: $nameVersionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      name
      description
      status
      versionId
      hardwareType {
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            uiAttributes {
              name
              value
            }
          }
          rules {
            name
            description
          }
          slots {
            name
            description
            type
          }
        }
        settings
      }
      hardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareTemplateByStatusAndNameAndVersionIdQueryVariables,
  APITypes.ListHardwareTemplateByStatusAndNameAndVersionIdQuery
>;
export const getHardwareSlotTemplate = /* GraphQL */ `query GetHardwareSlotTemplate($name: String!, $versionId: String!) {
  getHardwareSlotTemplate(name: $name, versionId: $versionId) {
    name
    description
    status
    versionId
    hardwareTemplateName
    hardwareTemplateVersionId
    hardwareTemplate {
      name
      description
      status
      versionId
      hardwareType {
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            uiAttributes {
              name
              value
            }
          }
          rules {
            name
            description
          }
          slots {
            name
            description
            type
          }
        }
        settings
      }
      hardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      createdAt
      updatedAt
    }
    parentHardwareSlotTemplateName
    parentHardwareSlotTemplateVersionId
    parentHardwareSlotTemplate {
      name
      description
      status
      versionId
      hardwareTemplateName
      hardwareTemplateVersionId
      hardwareTemplate {
        name
        description
        status
        versionId
        hardwareType {
          name
          description
          hardwareDevices {
            name
            description
            fields {
              name
            }
            rules {
              name
              description
            }
            slots {
              name
              description
              type
            }
          }
          settings
        }
        hardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        createdAt
        updatedAt
      }
      parentHardwareSlotTemplateName
      parentHardwareSlotTemplateVersionId
      parentHardwareSlotTemplate {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      childHardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      settings
      createdAt
      updatedAt
    }
    childHardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    settings
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetHardwareSlotTemplateQueryVariables,
  APITypes.GetHardwareSlotTemplateQuery
>;
export const listHardwareSlotTemplates = /* GraphQL */ `query ListHardwareSlotTemplates(
  $name: String
  $versionId: ModelStringKeyConditionInput
  $filter: ModelHardwareSlotTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHardwareSlotTemplates(
    name: $name
    versionId: $versionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      name
      description
      status
      versionId
      hardwareTemplateName
      hardwareTemplateVersionId
      hardwareTemplate {
        name
        description
        status
        versionId
        hardwareType {
          name
          description
          hardwareDevices {
            name
            description
            fields {
              name
            }
            rules {
              name
              description
            }
            slots {
              name
              description
              type
            }
          }
          settings
        }
        hardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        createdAt
        updatedAt
      }
      parentHardwareSlotTemplateName
      parentHardwareSlotTemplateVersionId
      parentHardwareSlotTemplate {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      childHardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      settings
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareSlotTemplatesQueryVariables,
  APITypes.ListHardwareSlotTemplatesQuery
>;
export const listHardwareSlotTemplateByStatusAndNameAndVersionId = /* GraphQL */ `query ListHardwareSlotTemplateByStatusAndNameAndVersionId(
  $status: Status!
  $nameVersionId: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHardwareSlotTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listHardwareSlotTemplateByStatusAndNameAndVersionId(
    status: $status
    nameVersionId: $nameVersionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      name
      description
      status
      versionId
      hardwareTemplateName
      hardwareTemplateVersionId
      hardwareTemplate {
        name
        description
        status
        versionId
        hardwareType {
          name
          description
          hardwareDevices {
            name
            description
            fields {
              name
            }
            rules {
              name
              description
            }
            slots {
              name
              description
              type
            }
          }
          settings
        }
        hardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        createdAt
        updatedAt
      }
      parentHardwareSlotTemplateName
      parentHardwareSlotTemplateVersionId
      parentHardwareSlotTemplate {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      childHardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      settings
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareSlotTemplateByStatusAndNameAndVersionIdQueryVariables,
  APITypes.ListHardwareSlotTemplateByStatusAndNameAndVersionIdQuery
>;
export const getSiteHardwareConfiguration = /* GraphQL */ `query GetSiteHardwareConfiguration($id: ID!) {
  getSiteHardwareConfiguration(id: $id) {
    id
    name
    versionId
    description
    status
    siteCode
    devices {
      id
      name
      hardwareTypeName
      hardwareDeviceName
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      slots {
        name
        description
        type
        slotSettings {
          key
          value
        }
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSiteHardwareConfigurationQueryVariables,
  APITypes.GetSiteHardwareConfigurationQuery
>;
export const listSiteHardwareConfigurations = /* GraphQL */ `query ListSiteHardwareConfigurations(
  $id: ID
  $filter: ModelSiteHardwareConfigurationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSiteHardwareConfigurations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      versionId
      description
      status
      siteCode
      devices {
        id
        name
        hardwareTypeName
        hardwareDeviceName
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        slots {
          name
          description
          type
          slotSettings {
            key
            value
          }
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSiteHardwareConfigurationsQueryVariables,
  APITypes.ListSiteHardwareConfigurationsQuery
>;
export const listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName = /* GraphQL */ `query ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName(
  $siteCode: String!
  $statusVersionIdName: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSiteHardwareConfigurationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName(
    siteCode: $siteCode
    statusVersionIdName: $statusVersionIdName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      versionId
      description
      status
      siteCode
      devices {
        id
        name
        hardwareTypeName
        hardwareDeviceName
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        slots {
          name
          description
          type
          slotSettings {
            key
            value
          }
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQueryVariables,
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery
>;
export const listSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus = /* GraphQL */ `query ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus(
  $siteCode: String!
  $nameVersionIdStatus: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSiteHardwareConfigurationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus(
    siteCode: $siteCode
    nameVersionIdStatus: $nameVersionIdStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      versionId
      description
      status
      siteCode
      devices {
        id
        name
        hardwareTypeName
        hardwareDeviceName
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        slots {
          name
          description
          type
          slotSettings {
            key
            value
          }
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQueryVariables,
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQuery
>;
