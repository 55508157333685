import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import {
  UserAction,
  CreateUserActionInput,
  CreateUserActionMutation,
  UserPreferences,
  UpdateUserPreferencesInput,
  UpdateUserPreferencesMutation,
  ApplicationSettings,
  CreateUserPreferencesInput,
  CreateUserPreferencesMutation,
  GetApplicationSettingsQuery,
  GetUserPreferencesQuery,
  ListApplicationSettingsQuery,
} from '../API';
import {
  createUserAction as createUserActionMutation,
  createUserPreferences as createUserPreferencesMutation,
  updateUserPreferences as updateUserPreferencesMutation,
 } from "src/graphql/mutations";
import { getApplicationSettings, getUserPreferences, listApplicationSettings } from 'src/graphql/queries';
import { authenticatedUsername } from './Auth/AuthenticateUser';
import { debug } from 'src/utils';

export const ttl = (years: number): number => {
  const ttlDate = new Date();
  ttlDate.setFullYear(ttlDate.getFullYear()+years);
  const ttlEpochInt: number = Math.round(ttlDate.getTime()/1000);
  return ttlEpochInt;
}

export const auditDecorator = function(actionName: string, fn: Function) {
  debug(`auditorDecorator() actionName is ${actionName}`);
  return function(...args: any[]) {
    debug(`auditorDecorator() actionName is ${actionName}`);
    debug(`auditorDecorator() args is ${args}`);
    const userActionInput: CreateUserActionInput = {
      actionTime: Date.now(),
      actionType: actionName,
      ttl: Math.round((Date.now()+10000)/1000),
      userId: authenticatedUsername,
    };
    createUserAction(userActionInput);
    return fn(...args);
  }
};

export async function createUserAction(userActionInput: CreateUserActionInput): Promise<UserAction | null> {
  debug(`createUserAction() userActionInput is ${JSON.stringify(userActionInput)}`);

  let newUserAction: UserAction | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createUserActionMutation,
      {
        input: userActionInput,
      })) as GraphQLResult<CreateUserActionMutation>;
    if (response.data && response.data.createUserAction) {
      newUserAction = response.data.createUserAction as UserAction;
    }
  } catch(error) {
    console.error(`createUserAction() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(newUserAction);
};

export let createUserPreferences = async (userPreferences: CreateUserPreferencesInput): Promise<UserPreferences | null> => {
  debug(`createUserPreferences() userPreferences is ${JSON.stringify(userPreferences)}`);
 
  let newUserPrefs: UserPreferences | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(createUserPreferencesMutation,
      {
        input: userPreferences,
      })) as GraphQLResult<CreateUserPreferencesMutation>;
    if (response.data && response.data.createUserPreferences) {
      newUserPrefs = response.data.createUserPreferences as UserPreferences;
    }
  } catch(error) {
    console.error(`createUserPreferences() error is ${JSON.stringify(error)}`); 
    throw error;
  }
  
  return(newUserPrefs);
};
createUserPreferences = auditDecorator('createUserPreferences', createUserPreferences);

export let queryUserPreferences = async (userId: string): Promise<UserPreferences | null> => {
  debug(`queryUserPreferences() userId is ${userId}`);
 
  let userPrefs: UserPreferences | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(getUserPreferences,
      {
        userId,
      })) as GraphQLResult<GetUserPreferencesQuery>;
    if (response.data && response.data.getUserPreferences) {
      userPrefs = response.data.getUserPreferences as UserPreferences;
    }
    debug(`response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`queryUserPreferences() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(userPrefs);
};    
queryUserPreferences = auditDecorator('queryUserPreferences', queryUserPreferences);

export let queryAllApplicationSettings = async (): Promise<ApplicationSettings[]> => {
  debug(`queryAllApplicationSettings()`);
 
  let allApplicationSettings: ApplicationSettings[] = [];
  
  try {
    const response = await API.graphql(graphqlOperation(listApplicationSettings, {})) as GraphQLResult<ListApplicationSettingsQuery>;
    if (response.data && response.data.listApplicationSettings) {
      allApplicationSettings = response.data.listApplicationSettings.items as ApplicationSettings[];
    }
    debug(`queryAllApplicationSettings() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`queryAllApplicationSettings() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(allApplicationSettings);
};    
queryAllApplicationSettings = auditDecorator('queryAllApplicationSettings', queryAllApplicationSettings);

export let queryApplicationSettings = async (versionId: string): Promise<ApplicationSettings | null> => {
  debug(`queryApplicationSettings() userId is ${versionId}`);
 
  let applicationSettings: ApplicationSettings | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(getApplicationSettings,
      {
        versionId,
      })) as GraphQLResult<GetApplicationSettingsQuery>;
    if (response.data && response.data.getApplicationSettings) {
      applicationSettings = response.data.getApplicationSettings as ApplicationSettings;
    }
    debug(`response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`queryApplicationSettings() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(applicationSettings);
};    
queryApplicationSettings = auditDecorator('queryApplicationSettings', queryApplicationSettings);

export let querySites = async (): Promise<{SiteCode: string}[]> => {
  debug(`querySites()`);

  let sites: {SiteCode: string}[] = [];

  try {
    sites.push({SiteCode: 'AUS2'});
    sites.push({SiteCode: 'IND9'});
  } catch(error) {
    console.error(`querySites() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return sites;

};
querySites = auditDecorator('querySites', querySites);

export let updateUserPreferences = async (userPreferences: UpdateUserPreferencesInput): Promise<UserPreferences | null> => {
  debug(`updateUserPreferences() userPreferences is ${JSON.stringify(userPreferences)}`);
  
  let updatedUserPreferences: UserPreferences | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(updateUserPreferencesMutation,
      {
        input: userPreferences,
      })) as GraphQLResult<UpdateUserPreferencesMutation>;
    if (response.data && response.data.updateUserPreferences) {
      updatedUserPreferences = response.data.updateUserPreferences as UserPreferences;
    }
  } catch(error) {
    console.error(`updateUserPreferences() error is ${JSON.stringify(error)}`); 
    throw error;
  }
  
  return(updatedUserPreferences);
};
updateUserPreferences = auditDecorator('updateUserPreferences', updateUserPreferences);