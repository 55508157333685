/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserPreferences = /* GraphQL */ `mutation CreateUserPreferences(
  $input: CreateUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  createUserPreferences(input: $input, condition: $condition) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPreferencesMutationVariables,
  APITypes.CreateUserPreferencesMutation
>;
export const updateUserPreferences = /* GraphQL */ `mutation UpdateUserPreferences(
  $input: UpdateUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  updateUserPreferences(input: $input, condition: $condition) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPreferencesMutationVariables,
  APITypes.UpdateUserPreferencesMutation
>;
export const deleteUserPreferences = /* GraphQL */ `mutation DeleteUserPreferences(
  $input: DeleteUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  deleteUserPreferences(input: $input, condition: $condition) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPreferencesMutationVariables,
  APITypes.DeleteUserPreferencesMutation
>;
export const createUserAction = /* GraphQL */ `mutation CreateUserAction(
  $input: CreateUserActionInput!
  $condition: ModelUserActionConditionInput
) {
  createUserAction(input: $input, condition: $condition) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUserActionMutationVariables,
  APITypes.CreateUserActionMutation
>;
export const updateUserAction = /* GraphQL */ `mutation UpdateUserAction(
  $input: UpdateUserActionInput!
  $condition: ModelUserActionConditionInput
) {
  updateUserAction(input: $input, condition: $condition) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserActionMutationVariables,
  APITypes.UpdateUserActionMutation
>;
export const deleteUserAction = /* GraphQL */ `mutation DeleteUserAction(
  $input: DeleteUserActionInput!
  $condition: ModelUserActionConditionInput
) {
  deleteUserAction(input: $input, condition: $condition) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserActionMutationVariables,
  APITypes.DeleteUserActionMutation
>;
export const createApplicationSettings = /* GraphQL */ `mutation CreateApplicationSettings(
  $input: CreateApplicationSettingsInput!
  $condition: ModelApplicationSettingsConditionInput
) {
  createApplicationSettings(input: $input, condition: $condition) {
    versionId
    hardwareTypes {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    slotTypes {
      name
      description
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateApplicationSettingsMutationVariables,
  APITypes.CreateApplicationSettingsMutation
>;
export const updateApplicationSettings = /* GraphQL */ `mutation UpdateApplicationSettings(
  $input: UpdateApplicationSettingsInput!
  $condition: ModelApplicationSettingsConditionInput
) {
  updateApplicationSettings(input: $input, condition: $condition) {
    versionId
    hardwareTypes {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    slotTypes {
      name
      description
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateApplicationSettingsMutationVariables,
  APITypes.UpdateApplicationSettingsMutation
>;
export const deleteApplicationSettings = /* GraphQL */ `mutation DeleteApplicationSettings(
  $input: DeleteApplicationSettingsInput!
  $condition: ModelApplicationSettingsConditionInput
) {
  deleteApplicationSettings(input: $input, condition: $condition) {
    versionId
    hardwareTypes {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    slotTypes {
      name
      description
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteApplicationSettingsMutationVariables,
  APITypes.DeleteApplicationSettingsMutation
>;
export const createHardwareTemplate = /* GraphQL */ `mutation CreateHardwareTemplate(
  $input: CreateHardwareTemplateInput!
  $condition: ModelHardwareTemplateConditionInput
) {
  createHardwareTemplate(input: $input, condition: $condition) {
    name
    description
    status
    versionId
    hardwareType {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    hardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    deviceSettings {
      key
      value
      uiAttributes {
        name
        value
      }
    }
    boardSettings {
      columnSpan
      columnOffset
      rowSpan
      sequence
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateHardwareTemplateMutationVariables,
  APITypes.CreateHardwareTemplateMutation
>;
export const updateHardwareTemplate = /* GraphQL */ `mutation UpdateHardwareTemplate(
  $input: UpdateHardwareTemplateInput!
  $condition: ModelHardwareTemplateConditionInput
) {
  updateHardwareTemplate(input: $input, condition: $condition) {
    name
    description
    status
    versionId
    hardwareType {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    hardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    deviceSettings {
      key
      value
      uiAttributes {
        name
        value
      }
    }
    boardSettings {
      columnSpan
      columnOffset
      rowSpan
      sequence
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateHardwareTemplateMutationVariables,
  APITypes.UpdateHardwareTemplateMutation
>;
export const deleteHardwareTemplate = /* GraphQL */ `mutation DeleteHardwareTemplate(
  $input: DeleteHardwareTemplateInput!
  $condition: ModelHardwareTemplateConditionInput
) {
  deleteHardwareTemplate(input: $input, condition: $condition) {
    name
    description
    status
    versionId
    hardwareType {
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          uiAttributes {
            name
            value
          }
        }
        rules {
          name
          description
        }
        slots {
          name
          description
          type
        }
      }
      settings
    }
    hardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    deviceSettings {
      key
      value
      uiAttributes {
        name
        value
      }
    }
    boardSettings {
      columnSpan
      columnOffset
      rowSpan
      sequence
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteHardwareTemplateMutationVariables,
  APITypes.DeleteHardwareTemplateMutation
>;
export const createHardwareSlotTemplate = /* GraphQL */ `mutation CreateHardwareSlotTemplate(
  $input: CreateHardwareSlotTemplateInput!
  $condition: ModelHardwareSlotTemplateConditionInput
) {
  createHardwareSlotTemplate(input: $input, condition: $condition) {
    name
    description
    status
    versionId
    hardwareTemplateName
    hardwareTemplateVersionId
    hardwareTemplate {
      name
      description
      status
      versionId
      hardwareType {
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            uiAttributes {
              name
              value
            }
          }
          rules {
            name
            description
          }
          slots {
            name
            description
            type
          }
        }
        settings
      }
      hardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      createdAt
      updatedAt
    }
    parentHardwareSlotTemplateName
    parentHardwareSlotTemplateVersionId
    parentHardwareSlotTemplate {
      name
      description
      status
      versionId
      hardwareTemplateName
      hardwareTemplateVersionId
      hardwareTemplate {
        name
        description
        status
        versionId
        hardwareType {
          name
          description
          hardwareDevices {
            name
            description
            fields {
              name
            }
            rules {
              name
              description
            }
            slots {
              name
              description
              type
            }
          }
          settings
        }
        hardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        createdAt
        updatedAt
      }
      parentHardwareSlotTemplateName
      parentHardwareSlotTemplateVersionId
      parentHardwareSlotTemplate {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      childHardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      settings
      createdAt
      updatedAt
    }
    childHardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    settings
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateHardwareSlotTemplateMutationVariables,
  APITypes.CreateHardwareSlotTemplateMutation
>;
export const updateHardwareSlotTemplate = /* GraphQL */ `mutation UpdateHardwareSlotTemplate(
  $input: UpdateHardwareSlotTemplateInput!
  $condition: ModelHardwareSlotTemplateConditionInput
) {
  updateHardwareSlotTemplate(input: $input, condition: $condition) {
    name
    description
    status
    versionId
    hardwareTemplateName
    hardwareTemplateVersionId
    hardwareTemplate {
      name
      description
      status
      versionId
      hardwareType {
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            uiAttributes {
              name
              value
            }
          }
          rules {
            name
            description
          }
          slots {
            name
            description
            type
          }
        }
        settings
      }
      hardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      createdAt
      updatedAt
    }
    parentHardwareSlotTemplateName
    parentHardwareSlotTemplateVersionId
    parentHardwareSlotTemplate {
      name
      description
      status
      versionId
      hardwareTemplateName
      hardwareTemplateVersionId
      hardwareTemplate {
        name
        description
        status
        versionId
        hardwareType {
          name
          description
          hardwareDevices {
            name
            description
            fields {
              name
            }
            rules {
              name
              description
            }
            slots {
              name
              description
              type
            }
          }
          settings
        }
        hardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        createdAt
        updatedAt
      }
      parentHardwareSlotTemplateName
      parentHardwareSlotTemplateVersionId
      parentHardwareSlotTemplate {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      childHardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      settings
      createdAt
      updatedAt
    }
    childHardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    settings
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateHardwareSlotTemplateMutationVariables,
  APITypes.UpdateHardwareSlotTemplateMutation
>;
export const deleteHardwareSlotTemplate = /* GraphQL */ `mutation DeleteHardwareSlotTemplate(
  $input: DeleteHardwareSlotTemplateInput!
  $condition: ModelHardwareSlotTemplateConditionInput
) {
  deleteHardwareSlotTemplate(input: $input, condition: $condition) {
    name
    description
    status
    versionId
    hardwareTemplateName
    hardwareTemplateVersionId
    hardwareTemplate {
      name
      description
      status
      versionId
      hardwareType {
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            uiAttributes {
              name
              value
            }
          }
          rules {
            name
            description
          }
          slots {
            name
            description
            type
          }
        }
        settings
      }
      hardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      createdAt
      updatedAt
    }
    parentHardwareSlotTemplateName
    parentHardwareSlotTemplateVersionId
    parentHardwareSlotTemplate {
      name
      description
      status
      versionId
      hardwareTemplateName
      hardwareTemplateVersionId
      hardwareTemplate {
        name
        description
        status
        versionId
        hardwareType {
          name
          description
          hardwareDevices {
            name
            description
            fields {
              name
            }
            rules {
              name
              description
            }
            slots {
              name
              description
              type
            }
          }
          settings
        }
        hardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceSettings {
          key
          value
          uiAttributes {
            name
            value
          }
        }
        boardSettings {
          columnSpan
          columnOffset
          rowSpan
          sequence
        }
        createdAt
        updatedAt
      }
      parentHardwareSlotTemplateName
      parentHardwareSlotTemplateVersionId
      parentHardwareSlotTemplate {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      childHardwareSlotTemplates {
        items {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      settings
      createdAt
      updatedAt
    }
    childHardwareSlotTemplates {
      items {
        name
        description
        status
        versionId
        hardwareTemplateName
        hardwareTemplateVersionId
        hardwareTemplate {
          name
          description
          status
          versionId
          hardwareType {
            name
            description
            hardwareDevices {
              name
              description
            }
            settings
          }
          hardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceSettings {
            key
            value
            uiAttributes {
              name
              value
            }
          }
          boardSettings {
            columnSpan
            columnOffset
            rowSpan
            sequence
          }
          createdAt
          updatedAt
        }
        parentHardwareSlotTemplateName
        parentHardwareSlotTemplateVersionId
        parentHardwareSlotTemplate {
          name
          description
          status
          versionId
          hardwareTemplateName
          hardwareTemplateVersionId
          hardwareTemplate {
            name
            description
            status
            versionId
            hardwareType {
              name
              description
              settings
            }
            hardwareSlotTemplates {
              nextToken
            }
            deviceSettings {
              key
              value
            }
            boardSettings {
              columnSpan
              columnOffset
              rowSpan
              sequence
            }
            createdAt
            updatedAt
          }
          parentHardwareSlotTemplateName
          parentHardwareSlotTemplateVersionId
          parentHardwareSlotTemplate {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          childHardwareSlotTemplates {
            items {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            nextToken
          }
          settings
          createdAt
          updatedAt
        }
        childHardwareSlotTemplates {
          items {
            name
            description
            status
            versionId
            hardwareTemplateName
            hardwareTemplateVersionId
            hardwareTemplate {
              name
              description
              status
              versionId
              createdAt
              updatedAt
            }
            parentHardwareSlotTemplateName
            parentHardwareSlotTemplateVersionId
            parentHardwareSlotTemplate {
              name
              description
              status
              versionId
              hardwareTemplateName
              hardwareTemplateVersionId
              parentHardwareSlotTemplateName
              parentHardwareSlotTemplateVersionId
              settings
              createdAt
              updatedAt
            }
            childHardwareSlotTemplates {
              nextToken
            }
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
    settings
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteHardwareSlotTemplateMutationVariables,
  APITypes.DeleteHardwareSlotTemplateMutation
>;
export const createSiteHardwareConfiguration = /* GraphQL */ `mutation CreateSiteHardwareConfiguration(
  $input: CreateSiteHardwareConfigurationInput!
  $condition: ModelSiteHardwareConfigurationConditionInput
) {
  createSiteHardwareConfiguration(input: $input, condition: $condition) {
    id
    name
    versionId
    description
    status
    siteCode
    devices {
      id
      name
      hardwareTypeName
      hardwareDeviceName
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      slots {
        name
        description
        type
        slotSettings {
          key
          value
        }
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSiteHardwareConfigurationMutationVariables,
  APITypes.CreateSiteHardwareConfigurationMutation
>;
export const updateSiteHardwareConfiguration = /* GraphQL */ `mutation UpdateSiteHardwareConfiguration(
  $input: UpdateSiteHardwareConfigurationInput!
  $condition: ModelSiteHardwareConfigurationConditionInput
) {
  updateSiteHardwareConfiguration(input: $input, condition: $condition) {
    id
    name
    versionId
    description
    status
    siteCode
    devices {
      id
      name
      hardwareTypeName
      hardwareDeviceName
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      slots {
        name
        description
        type
        slotSettings {
          key
          value
        }
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSiteHardwareConfigurationMutationVariables,
  APITypes.UpdateSiteHardwareConfigurationMutation
>;
export const deleteSiteHardwareConfiguration = /* GraphQL */ `mutation DeleteSiteHardwareConfiguration(
  $input: DeleteSiteHardwareConfigurationInput!
  $condition: ModelSiteHardwareConfigurationConditionInput
) {
  deleteSiteHardwareConfiguration(input: $input, condition: $condition) {
    id
    name
    versionId
    description
    status
    siteCode
    devices {
      id
      name
      hardwareTypeName
      hardwareDeviceName
      deviceSettings {
        key
        value
        uiAttributes {
          name
          value
        }
      }
      boardSettings {
        columnSpan
        columnOffset
        rowSpan
        sequence
      }
      slots {
        name
        description
        type
        slotSettings {
          key
          value
        }
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSiteHardwareConfigurationMutationVariables,
  APITypes.DeleteSiteHardwareConfigurationMutation
>;
