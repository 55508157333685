import Board, { BoardProps } from '@amzn/awsui-board-components/board';
import { Container } from '@amzn/awsui-components-react';
import { Device, DeviceSettings, Slot } from 'src/API';
import { DocumentBoardItem } from './DocumentBoardItem';
import { debug } from 'src/utils';
import { useEffect, useState } from 'react';

export interface IDocumentBoardItem extends BoardProps.Item {
  data: {
    boardSettings: {
      columnOffset: any;
      columnSpan: number;
      rowSpan: number;
      sequence: number;
    }
    deviceSettings: DeviceSettings[];
    hardwareDeviceName: string;
    hardwareTypeName: string;
    id: string;
    name: string;
    title: string;
    slots: Slot[];
  },
}

export interface IDocumentBoardProps {
  documentChanged: Function;
  documentItemUpdate: Function;
  documentItemRemove: Function;
  documentItemSave: Function;
  documentItemSaving: boolean;
  documentItemUndoChanges: Function;
  documentItems: (Device | null)[] | undefined | null;
};

export const DocumentBoard = (props: IDocumentBoardProps) => {
  debug(`DocumentBoard() props is ${JSON.stringify(props)}`);

  const [documentBoardItems, setDocumentBoardItems] = useState<IDocumentBoardItem[]>([]);
  const [documentItems, setDocumentItems] = useState<(Device | null)[]>(props.documentItems || []);

  const createDocumentBoardItems = (documentItems: (Device | null)[]): BoardProps.Item<any>[] => {
    debug(`DocumentBoard() createDocumentBoardItems() documentItems is ${JSON.stringify(documentItems)}`);
    if (!documentItems) return [];
    const documentBoardItems: BoardProps.Item[] | undefined = documentItems
      ?.map((item) => {
        return({
          data: {
            boardSettings: {
              columnOffset: item?.boardSettings?.columnOffset,
              columnSpan: item?.boardSettings?.columnSpan,
              rowSpan: item?.boardSettings?.rowSpan,
              sequence: item?.boardSettings?.sequence !== undefined && item?.boardSettings?.sequence !== null
                ? item.boardSettings.sequence
                : documentItems.length+1,
            },
            deviceSettings: item?.deviceSettings,
            hardwareDeviceName: item?.hardwareDeviceName,
            hardwareTypeName: item?.hardwareTypeName,
            id: item?.id,
            name: item?.name,
            title: `${item?.hardwareTypeName}-${item?.hardwareDeviceName}`,
            slots: item?.slots,
          },
          columnOffset: item?.boardSettings?.columnOffset ? JSON.parse(item?.boardSettings?.columnOffset) : undefined,
          columnSpan: item?.boardSettings?.columnSpan,
          id: item?.id || '',
          rowSpan: item?.boardSettings?.rowSpan,
        });
      })
      .sort((a, b) => {
        debug(`DocumentBoard() createDocumentBoardItems() sort() a.data.name is ${a.data.name} a.data.boardSettings.sequence ${a.data.boardSettings.sequence} b.data.name is ${b.data.name} b.data.boardSettings.sequence is ${b.data.boardSettings.sequence}`);
        let result = 0;
        if (a.data.boardSettings.sequence === undefined) result = 1;
        if (b.data.boardSettings.sequence === undefined) result = -1;
        if (a.data.boardSettings.sequence !== null && b.data.boardSettings.sequence !== null ) {
          result = a.data.boardSettings.sequence < b.data.boardSettings.sequence ? -1 : 1;
        }
        return(result);
      });
    debug(`DocumentBoard() createDocumentBoardItems() documentBoardItems is ${JSON.stringify(documentBoardItems)}`);
    return(documentBoardItems || []);
  };

  const setDocumentItem = (documentItem: Device) => {
    debug(`DocumentBoard() setDocumentItem() documentItem is ${JSON.stringify(documentItem)}`);
    const newDocumentItems = documentItems.filter(di => di?.id !== documentItem.id);
    newDocumentItems.push(documentItem);
    setDocumentItems(newDocumentItems);
    props.documentItemUpdate(documentItem);
  };

  const handleBoardOnItemsChange = (event: any) => {
    debug(`DocumentBoard() handleBoardOnItemsChange() event is ${JSON.stringify(event)}`);
    const currentDocumentItems = documentItems;
    for (const eventItem of event.detail.items) {
      const currentDocumentItem = currentDocumentItems.find(i => i?.id === eventItem.id) as any;
      if (!currentDocumentItem) continue;
      debug(`DocumentBoard() handleBoardOnItemsChange() event.item.boardSettings is ${JSON.stringify(eventItem.data.boardSettings)}`);
      debug(`DocumentBoard() handleBoardOnItemsChange() currentDocumentItem.boardSettings is ${JSON.stringify(currentDocumentItem.boardSettings)}`);
      if (eventItem.rowSpan !== currentDocumentItem.boardSettings?.rowSpan
        || eventItem.columnOffset !== currentDocumentItem.boardSettings?.columnOffset
        || eventItem.columnSpan !== currentDocumentItem.boardSettings?.columnSpan)
      {
        debug(`DocumentBoard() handleBoardOnItemsChange() calling props.updateDocumentDevice()`);
        currentDocumentItem.boardSettings = {
          ...currentDocumentItem.boardSettings,
          columnOffset: JSON.stringify(eventItem.columnOffset),
          columnSpan: eventItem.columnSpan,
          rowSpan: eventItem.rowSpan,
        };
        debug(`DocumentBoard() handleBoardOnItemsChange() currentDocumentItem is ${JSON.stringify(currentDocumentItem)}`);
        props.documentItemUpdate(currentDocumentItem);
      }
    }
    setDocumentBoardItems(event.detail.items);
    props.documentChanged(true);
  };

  useEffect(() => {
    debug(`DocumentBoard() useEffect()[props.documentItems] props.documentItems is ${JSON.stringify(props.documentItems)}`);
    setDocumentBoardItems(createDocumentBoardItems(props.documentItems || []));
    setDocumentItems(props.documentItems ||[]);
  }, [props.documentItems]);

  useEffect(() => {
    debug(`DocumentBoard() useEffect()[documentItems] documentItems is ${JSON.stringify(documentItems)}`);
    setDocumentBoardItems(createDocumentBoardItems(documentItems));
  }, [documentItems]);

  return (
    <Container
      disableContentPaddings
      disableHeaderPaddings
      fitHeight
    >
    <Board
      // @ts-ignore
      renderItem={(item: IDocumentBoardItem) => {
        debug(`DocumentBoard() renderItem() item is ${JSON.stringify(item)}`);
        const documentItem: Device = 
          {
            boardSettings: {
              columnSpan: item.columnSpan || item.data.boardSettings.columnSpan,
              columnOffset: JSON.stringify(item.columnOffset) || item.data.boardSettings.columnOffset,
              rowSpan: item.rowSpan || item.data.boardSettings.rowSpan,
              sequence: documentBoardItems.findIndex(i => i.data.id === item.data.id),
            },
            deviceSettings: item.data.deviceSettings,
            hardwareTypeName: item.data.hardwareTypeName,
            hardwareDeviceName: item.data.hardwareDeviceName,
            id: item.data.id,
            name: item.data.name,
            slots: item.data.slots,
          };
        return(
          <DocumentBoardItem
            documentBoardItem={documentItem}
            documentItemRemove={props.documentItemRemove}
            documentItemSave={props.documentItemSave}
            documentItemSaving={props.documentItemSaving}
            documentItemUndoChanges={props.documentItemUndoChanges}
            key={item.id}
            setDocumentBoardItem={setDocumentItem}
          />);
        }
      }
      onItemsChange={(event: any) => {
        debug(`DocumentBoard() onItemsChange() event is ${JSON.stringify(event)}`);
        handleBoardOnItemsChange(event);
      }}
      items={documentBoardItems}
      i18nStrings={(() => {
        function createAnnouncement(
          operationAnnouncement: string,
          conflicts: any[] | readonly BoardProps.Item<unknown>[],
          disturbed: string | any[] | readonly BoardProps.Item<unknown>[]
        ) {
          const disturbedAnnouncement = disturbed.length > 0
            ? `Disturbed ${disturbed.length} items.`
            : '';
          return [
            operationAnnouncement,
            disturbedAnnouncement
          ]
            .filter(Boolean)
            .join(' ');
        }
        return {
          liveAnnouncementDndStarted: operationType => operationType === 'resize'
            ? 'Resizing'
            : 'Dragging',
          liveAnnouncementDndItemReordered: operation => {
            const columns = `column ${operation.placement
              .x + 1}`;
            const rows = `row ${operation.placement.y +
              1}`;
            return createAnnouncement(
              `Item moved to ${operation.direction === 'horizontal'
                ? columns
                : rows}.`,
              operation.conflicts,
              operation.disturbed
            );
          },
          liveAnnouncementDndItemResized: operation => {
            const columnsConstraint = operation.isMinimalColumnsReached
              ? ' (minimal)'
              : '';
            const rowsConstraint = operation.isMinimalRowsReached
              ? ' (minimal)'
              : '';
            const sizeAnnouncement = operation.direction === 'horizontal'
              ? `columns ${operation.placement.width}${columnsConstraint}`
              : `rows ${operation.placement.height}${rowsConstraint}`;
            return createAnnouncement(
              `Item resized to ${sizeAnnouncement}.`,
              operation.conflicts,
              operation.disturbed
            );
          },
          liveAnnouncementDndItemInserted: operation => {
            const columns = `column ${operation.placement
              .x + 1}`;
            const rows = `row ${operation.placement.y +
              1}`;
            return createAnnouncement(
              `Item inserted to ${columns}, ${rows}.`,
              operation.conflicts,
              operation.disturbed
            );
          },
          liveAnnouncementDndCommitted: operationType => `${operationType} committed`,
          liveAnnouncementDndDiscarded: operationType => `${operationType} discarded`,
          liveAnnouncementItemRemoved: (op: any) => createAnnouncement(
            `Removed item ${op.item?.data?.title}.`,
            [],
            op.disturbed
          ),
          navigationAriaLabel: 'Board navigation',
          navigationAriaDescription: 'Click on non-empty item to move focus over',
          navigationItemAriaLabel: (item: any) => item ? item?.data?.title : 'Empty'
        };
      })()}
      empty={undefined}
    />
    </Container>
  );
}