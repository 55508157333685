import {
  Alert,
  Box,
  Button,
  Container,
  Header,
  Modal,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  Device,
  SiteHardwareConfiguration,
  Status,
  UpdateSiteHardwareConfigurationInput,
} from 'src/API'
import { DocumentActions } from './DocumentActions';
import { DocumentBoard } from './DocumentBoard';
import { debug } from 'src/utils';
import { updateSiteHardwareConfiguration } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../common/constants';

export interface IDocumentProps {
  close: Function;
  document: SiteHardwareConfiguration;
  selectedApplicationSettingsVersion: SelectProps.Option;
  setSelectedApplicationSettingsVersion: Function;
  siteCode: string;
  updateCurrentEditDocument: Function;
};

export function Document(props: IDocumentProps) {
  debug(`Document() props is ${JSON.stringify(props)}`);

  const [document, setDocument] = useState<SiteHardwareConfiguration>(props.document);
  const [documentChanged, setDocumentChanged] = useState<boolean>(false);
  const [documentSaveError, setDocumentSaveError] = useState<string | null>(null);
  const [documentSaved, setDocumentSaved] = useState<boolean>(false);
  const [documentSaving, setDocumentSaving] = useState<boolean>(false);
  const [documentTemp, setDocumentTemp] = useState<SiteHardwareConfiguration>(props.document);
  const [documentIdForRemoval, setDocumentIdForRemoval] = useState<string | null>(null);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Documents.Document');

  const queryClient = useQueryClient();

  const updateSiteHardwareConfigurationMutation = useMutation(
    {
      mutationFn: updateSiteHardwareConfiguration,
      onSuccess: (data) => {
        setDocumentSaving(false);
        queryClient.invalidateQueries({ queryKey: [QueryKey.siteHardwareConfigurations] });
        setDocument(data!);
        props.updateCurrentEditDocument(data);
        setDocumentSaved(true);
      },
      onError: (error: any) => {
        setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
        setDocumentSaved(false);
        setDocumentSaving(false);
      },
    }
  );

  const addDocumentItem = (item: Device) => {
    debug(`Document() addDocumentItem() item is ${JSON.stringify(item)}`);
    setDocumentTemp({
      ...documentTemp!,
      devices: [
        ...(documentTemp!.devices ? documentTemp!.devices : []),
        item,
      ],
    });
    setDocumentChanged(true);
  };

  const updateDocumentDevice = (device: Device) => {
    debug(`Document() updateDocumentDevice() device is ${JSON.stringify(device)}`);
    debug(`Document() updateDocumentDevice() documentTemp is ${JSON.stringify(documentTemp)}`);
    if (!documentTemp) return;
    const updatedDocument = {...documentTemp};
    const newDevices = updatedDocument.devices?.filter(d => d?.id !== device.id) || [];
    debug(`Document() updateDocumentDevice() newDevices is ${JSON.stringify(newDevices)}`);
    newDevices.push(device);
    debug(`Document() updateDocumentDevice() newDevices is ${JSON.stringify(newDevices)}`);
    updatedDocument.devices = newDevices;
    debug(`Document() updateDocumentDevice() updatedDocument is ${JSON.stringify(updatedDocument)}`);
    setDocumentTemp(updatedDocument);
    setDocumentChanged(true);
    setDocumentSaved(false);
  };

  const undo = () => {
    setDocumentTemp(props.document);
  };

  const saveDocument = async () => {
    debug(`Document() saveDocument() documentTemp is ${JSON.stringify(documentTemp)}`);
    if (!documentTemp) return;
    setDocumentSaving(true);
    try {
      const updateSiteHardwareConfigurationInput: UpdateSiteHardwareConfigurationInput = {
        description: documentTemp.description,
        devices: documentTemp.devices,
        id: documentTemp.id,
        name: documentTemp.name,
        status: documentTemp.status,
        siteCode: documentTemp.siteCode,
        versionId: documentTemp.versionId,
      };
      debug(`Document() saveDocument() updateSiteHardwareConfigurationInput is ${JSON.stringify(updateSiteHardwareConfigurationInput)}`);
      await updateSiteHardwareConfigurationMutation.mutateAsync(updateSiteHardwareConfigurationInput);
    } catch(error: any) {
      setDocumentSaving(false);
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
    setDocumentSaving(false);
  };

  const saveDocumentItem = async (item: Device) => {
    debug(`Document() saveDocumentItem() item is ${JSON.stringify(item)}`);
    setDocumentSaving(true);
    if (!documentTemp) return;
    try {
      const updatedDocument = {...documentTemp};
      debug(`Document() saveDocumentItem() updatedDocument is ${JSON.stringify(updatedDocument)}`);
      const newDevices = updatedDocument.devices?.filter(d => d?.id !== item.id);
      debug(`Document() saveDocumentItem() newDevices is ${JSON.stringify(newDevices)}`);
      if (!newDevices) return;
      newDevices.push(item);
      updatedDocument.devices = newDevices;
      const updateSiteHardwareConfigurationInput: UpdateSiteHardwareConfigurationInput = {
        description: updatedDocument.description,
        devices: updatedDocument.devices,
        id: updatedDocument.id,
        name: updatedDocument.name,
        status: updatedDocument.status,
        siteCode: updatedDocument.siteCode,
        versionId: updatedDocument.versionId,
      };
      debug(`Document() saveDocumentItem() updateSiteHardwareConfigurationInput is ${JSON.stringify(updateSiteHardwareConfigurationInput)}`);
      await updateSiteHardwareConfigurationMutation.mutateAsync(updateSiteHardwareConfigurationInput);
      setDocumentTemp(updatedDocument);
      setDocumentChanged(true);
    } catch(error: any) {
      setDocumentSaving(false);
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
    setDocumentSaving(false);
  };

  const undoDocumentItemChange = async (item: Device) => {
    debug(`Document() undoDocumentItemChange() item is ${JSON.stringify(item)}`);
    const undoDocument = {...documentTemp};
    const newDevices = undoDocument.devices?.filter(d => d?.id !== item.id);
    debug(`Document() undoDocumentItemChange() newDevices is ${JSON.stringify(newDevices)}`);
    const previousDevice = document.devices?.find(d => d?.id === item.id);
    debug(`Document() undoDocumentItemChange() previousDevice is ${JSON.stringify(previousDevice)}`);
    if (previousDevice) newDevices?.push(previousDevice);
    setDocumentTemp({
      ...undoDocument,
      devices: newDevices,
    });
  };

  const setDocumentTempDescription = (description: string) => {
    debug(`Document() setDocumentTempDescription() description is ${description} documentTemp.description is ${documentTemp?.description}`);
    setDocumentTemp({
      ...documentTemp!,
      description: description || '',
    });
    if (documentTemp?.description !== description) setDocumentChanged(true);
  };

  const setDocumentTempStatus = (status: Status) => {
    debug(`Document() setDocumentTempStatus() status is ${status} documentTemp.status is ${documentTemp?.status}`);
    setDocumentTemp({
      ...documentTemp!,
      status: status || '',
    });
    if (documentTemp?.status !== status) setDocumentChanged(true);
  };

  const removeDocumentItem = (id: string) => {
    if (!documentTemp) return;
    const newDocumentItems = documentTemp.devices?.filter((di: any) => di.id !== id);
    if (!newDocumentItems) return;
    setDocumentTemp({
      ...documentTemp!,
      devices: newDocumentItems,
    });
    setDocumentChanged(true);
  };

  const closeDocument = () => {
    if (documentChanged) setShowCloseConfirmation(true);
    if (!documentChanged) props.close();
  };

  useEffect(() => {
    setDocument(props.document);
    setDocumentTemp(props.document);
    setDocumentChanged(false);
  }, [props.document]);

  useEffect(() => {
    if (!documentIdForRemoval) return;
    removeDocumentItem(documentIdForRemoval);
    setDocumentIdForRemoval(null);
  }, [documentIdForRemoval]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      <Modal
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowCloseConfirmation(false)}
                iconName='status-stopped'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                iconName='check'
                onClick={() => props.close()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {bundle.getMessage('confirm')}
          </Header>
        }
        onDismiss={() => setShowCloseConfirmation(false)}
        visible={showCloseConfirmation}
      >
        {`${bundle.getMessage('document-close-confirm')}`}
      </Modal>
    <Container
      header={
        <>
          {documentSaveError
          &&
          <Alert
            dismissible
            onDismiss={() => setDocumentSaveError(null)}
            type='error'
          >
            {documentSaveError}
          </Alert>}
          {documentSaved
          &&
          <Alert
            dismissible
            onDismiss={() => setDocumentSaved(false)}
            type='success'
          >
            {bundle.getMessage('document-saved')}
          </Alert>}
          <DocumentActions
            addDocumentItem={addDocumentItem}
            close={closeDocument}
            description={documentTemp?.description || ''}
            documentChanged={documentChanged}
            documentSaving={documentSaving}
            name={documentTemp?.name || ''}
            saveDocument={saveDocument}
            selectedApplicationSettingsVersion={props.selectedApplicationSettingsVersion}
            setDescription={(description: string) => setDocumentTempDescription(description)}
            setSelectedApplicationSettingsVersion={props.setSelectedApplicationSettingsVersion}
            setStatus={(status: Status) => setDocumentTempStatus(status)}
            status={documentTemp?.status || ''}
            undo={undo}
            updatedAt={documentTemp?.updatedAt || ''}
            versionId={documentTemp?.versionId || ''}
          />
        </>
      }
    >
      <DocumentBoard
        documentItemRemove={setDocumentIdForRemoval}
        documentItemSave={saveDocumentItem}
        documentItemSaving={documentSaving}
        documentItemUndoChanges={undoDocumentItemChange}
        documentItemUpdate={updateDocumentDevice}
        documentItems={documentTemp.devices}
        documentChanged={setDocumentChanged}
      />
    </Container>
    </>
  );
}
