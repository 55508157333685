/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserPreferencesInput = {
  userId: string,
  preferences: string,
};

export type ModelUserPreferencesConditionInput = {
  preferences?: ModelStringInput | null,
  and?: Array< ModelUserPreferencesConditionInput | null > | null,
  or?: Array< ModelUserPreferencesConditionInput | null > | null,
  not?: ModelUserPreferencesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type UserPreferences = {
  userId: string,
  preferences: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserPreferencesInput = {
  userId: string,
  preferences?: string | null,
};

export type DeleteUserPreferencesInput = {
  userId: string,
};

export type CreateUserActionInput = {
  userId: string,
  actionType: string,
  actionTime: number,
  parameters?: string | null,
  ttl: number,
};

export type ModelUserActionConditionInput = {
  parameters?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelUserActionConditionInput | null > | null,
  or?: Array< ModelUserActionConditionInput | null > | null,
  not?: ModelUserActionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UserAction = {
  userId: string,
  actionType: string,
  actionTime: number,
  parameters?: string | null,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserActionInput = {
  userId: string,
  actionType: string,
  actionTime: number,
  parameters?: string | null,
  ttl?: number | null,
};

export type DeleteUserActionInput = {
  userId: string,
  actionType: string,
  actionTime: number,
};

export type CreateApplicationSettingsInput = {
  versionId: string,
  hardwareTypes: Array< HardwareTypeInput | null >,
  slotTypes: Array< SlotTypeInput | null >,
  pacs: Array< PACSInput | null >,
};

export type HardwareTypeInput = {
  name: string,
  description: string,
  hardwareDevices?: Array< HardwareDeviceInput | null > | null,
  settings?: string | null,
};

export type HardwareDeviceInput = {
  name: string,
  description: string,
  fields?: Array< HardwareFieldInput > | null,
  rules?: Array< HardwareRuleInput > | null,
  slots?: Array< HardwareSlotInput > | null,
};

export type HardwareFieldInput = {
  name: string,
  uiAttributes?: Array< UIAttributeInput | null > | null,
};

export type UIAttributeInput = {
  name: string,
  value: string,
};

export type HardwareRuleInput = {
  name: string,
  description: string,
};

export type HardwareSlotInput = {
  name: string,
  description: string,
  type: string,
};

export type SlotTypeInput = {
  name: string,
  description: string,
  settings?: string | null,
};

export type PACSInput = {
  name: string,
  description: string,
  settings?: string | null,
};

export type ModelApplicationSettingsConditionInput = {
  and?: Array< ModelApplicationSettingsConditionInput | null > | null,
  or?: Array< ModelApplicationSettingsConditionInput | null > | null,
  not?: ModelApplicationSettingsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ApplicationSettings = {
  versionId: string,
  hardwareTypes:  Array<HardwareType | null >,
  slotTypes:  Array<SlotType | null >,
  pacs:  Array<PACS | null >,
  createdAt: string,
  updatedAt: string,
};

export type HardwareType = {
  name: string,
  description: string,
  hardwareDevices?:  Array<HardwareDevice | null > | null,
  settings?: string | null,
};

export type HardwareDevice = {
  name: string,
  description: string,
  fields?:  Array<HardwareField > | null,
  rules?:  Array<HardwareRule > | null,
  slots?:  Array<HardwareSlot > | null,
};

export type HardwareField = {
  name: string,
  uiAttributes?:  Array<UIAttribute | null > | null,
};

export type UIAttribute = {
  name: string,
  value: string,
};

export type HardwareRule = {
  name: string,
  description: string,
};

export type HardwareSlot = {
  name: string,
  description: string,
  type: string,
};

export type SlotType = {
  name: string,
  description: string,
  settings?: string | null,
};

export type PACS = {
  name: string,
  description: string,
  settings?: string | null,
};

export type UpdateApplicationSettingsInput = {
  versionId: string,
  hardwareTypes?: Array< HardwareTypeInput | null > | null,
  slotTypes?: Array< SlotTypeInput | null > | null,
  pacs?: Array< PACSInput | null > | null,
};

export type DeleteApplicationSettingsInput = {
  versionId: string,
};

export type CreateHardwareTemplateInput = {
  name: string,
  description: string,
  status: Status,
  versionId: string,
  hardwareType: HardwareTypeInput,
  deviceSettings?: DeviceSettingsInput | null,
  boardSettings?: BoardSettingsInput | null,
};

export enum Status {
  Active = "Active",
  Development = "Development",
  Inactive = "Inactive",
  Review = "Review",
  Temporary = "Temporary",
}


export type DeviceSettingsInput = {
  key: string,
  value: string,
  uiAttributes?: Array< UIAttributeInput | null > | null,
};

export type BoardSettingsInput = {
  columnSpan: number,
  columnOffset?: string | null,
  rowSpan: number,
  sequence?: number | null,
};

export type ModelHardwareTemplateConditionInput = {
  description?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  and?: Array< ModelHardwareTemplateConditionInput | null > | null,
  or?: Array< ModelHardwareTemplateConditionInput | null > | null,
  not?: ModelHardwareTemplateConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type HardwareTemplate = {
  name: string,
  description: string,
  status: Status,
  versionId: string,
  hardwareType: HardwareType,
  hardwareSlotTemplates?: ModelHardwareSlotTemplateConnection | null,
  deviceSettings?: DeviceSettings | null,
  boardSettings?: BoardSettings | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelHardwareSlotTemplateConnection = {
  items:  Array<HardwareSlotTemplate | null >,
  nextToken?: string | null,
};

export type HardwareSlotTemplate = {
  name: string,
  description: string,
  status: Status,
  versionId: string,
  hardwareTemplateName: string,
  hardwareTemplateVersionId: string,
  hardwareTemplate?: HardwareTemplate | null,
  parentHardwareSlotTemplateName?: string | null,
  parentHardwareSlotTemplateVersionId?: string | null,
  parentHardwareSlotTemplate?: HardwareSlotTemplate | null,
  childHardwareSlotTemplates?: ModelHardwareSlotTemplateConnection | null,
  settings?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type DeviceSettings = {
  key: string,
  value: string,
  uiAttributes?:  Array<UIAttribute | null > | null,
};

export type BoardSettings = {
  columnSpan: number,
  columnOffset?: string | null,
  rowSpan: number,
  sequence?: number | null,
};

export type UpdateHardwareTemplateInput = {
  name: string,
  description?: string | null,
  status?: Status | null,
  versionId: string,
  hardwareType?: HardwareTypeInput | null,
  deviceSettings?: DeviceSettingsInput | null,
  boardSettings?: BoardSettingsInput | null,
};

export type DeleteHardwareTemplateInput = {
  name: string,
  versionId: string,
};

export type CreateHardwareSlotTemplateInput = {
  name: string,
  description: string,
  status: Status,
  versionId: string,
  hardwareTemplateName: string,
  hardwareTemplateVersionId: string,
  parentHardwareSlotTemplateName?: string | null,
  parentHardwareSlotTemplateVersionId?: string | null,
  settings?: string | null,
};

export type ModelHardwareSlotTemplateConditionInput = {
  description?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  hardwareTemplateName?: ModelStringInput | null,
  hardwareTemplateVersionId?: ModelStringInput | null,
  parentHardwareSlotTemplateName?: ModelStringInput | null,
  parentHardwareSlotTemplateVersionId?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelHardwareSlotTemplateConditionInput | null > | null,
  or?: Array< ModelHardwareSlotTemplateConditionInput | null > | null,
  not?: ModelHardwareSlotTemplateConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateHardwareSlotTemplateInput = {
  name: string,
  description?: string | null,
  status?: Status | null,
  versionId: string,
  hardwareTemplateName?: string | null,
  hardwareTemplateVersionId?: string | null,
  parentHardwareSlotTemplateName?: string | null,
  parentHardwareSlotTemplateVersionId?: string | null,
  settings?: string | null,
};

export type DeleteHardwareSlotTemplateInput = {
  name: string,
  versionId: string,
};

export type CreateSiteHardwareConfigurationInput = {
  id?: string | null,
  name: string,
  versionId: string,
  description: string,
  status: Status,
  siteCode: string,
  devices?: Array< DeviceInput | null > | null,
};

export type DeviceInput = {
  id: string,
  name: string,
  hardwareTypeName: string,
  hardwareDeviceName: string,
  deviceSettings: Array< DeviceSettingsInput | null >,
  boardSettings: BoardSettingsInput,
  slots?: Array< SlotInput | null > | null,
};

export type SlotInput = {
  name: string,
  description: string,
  type: string,
  slotSettings?: Array< SlotSettingsInput | null > | null,
};

export type SlotSettingsInput = {
  key: string,
  value: string,
};

export type ModelSiteHardwareConfigurationConditionInput = {
  name?: ModelStringInput | null,
  versionId?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  siteCode?: ModelStringInput | null,
  and?: Array< ModelSiteHardwareConfigurationConditionInput | null > | null,
  or?: Array< ModelSiteHardwareConfigurationConditionInput | null > | null,
  not?: ModelSiteHardwareConfigurationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type SiteHardwareConfiguration = {
  id: string,
  name: string,
  versionId: string,
  description: string,
  status: Status,
  siteCode: string,
  devices?:  Array<Device | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type Device = {
  id: string,
  name: string,
  hardwareTypeName: string,
  hardwareDeviceName: string,
  deviceSettings:  Array<DeviceSettings | null >,
  boardSettings: BoardSettings,
  slots?:  Array<Slot | null > | null,
};

export type Slot = {
  name: string,
  description: string,
  type: string,
  slotSettings?:  Array<SlotSettings | null > | null,
};

export type SlotSettings = {
  key: string,
  value: string,
};

export type UpdateSiteHardwareConfigurationInput = {
  id: string,
  name?: string | null,
  versionId?: string | null,
  description?: string | null,
  status?: Status | null,
  siteCode?: string | null,
  devices?: Array< DeviceInput | null > | null,
};

export type DeleteSiteHardwareConfigurationInput = {
  id: string,
};

export type ModelUserPreferencesFilterInput = {
  userId?: ModelStringInput | null,
  preferences?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserPreferencesFilterInput | null > | null,
  or?: Array< ModelUserPreferencesFilterInput | null > | null,
  not?: ModelUserPreferencesFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserPreferencesConnection = {
  items:  Array<UserPreferences | null >,
  nextToken?: string | null,
};

export type ModelUserActionPrimaryCompositeKeyConditionInput = {
  eq?: ModelUserActionPrimaryCompositeKeyInput | null,
  le?: ModelUserActionPrimaryCompositeKeyInput | null,
  lt?: ModelUserActionPrimaryCompositeKeyInput | null,
  ge?: ModelUserActionPrimaryCompositeKeyInput | null,
  gt?: ModelUserActionPrimaryCompositeKeyInput | null,
  between?: Array< ModelUserActionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelUserActionPrimaryCompositeKeyInput | null,
};

export type ModelUserActionPrimaryCompositeKeyInput = {
  actionType?: string | null,
  actionTime?: number | null,
};

export type ModelUserActionFilterInput = {
  userId?: ModelStringInput | null,
  actionType?: ModelStringInput | null,
  actionTime?: ModelIntInput | null,
  parameters?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserActionFilterInput | null > | null,
  or?: Array< ModelUserActionFilterInput | null > | null,
  not?: ModelUserActionFilterInput | null,
};

export type ModelUserActionConnection = {
  items:  Array<UserAction | null >,
  nextToken?: string | null,
};

export type ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyConditionInput = {
  eq?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  le?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  lt?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  ge?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  gt?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  between?: Array< ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null > | null,
  beginsWith?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
};

export type ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput = {
  actionTime?: number | null,
  userId?: string | null,
};

export type ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyConditionInput = {
  eq?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  le?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  lt?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  ge?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  gt?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  between?: Array< ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null > | null,
  beginsWith?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
};

export type ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput = {
  actionType?: string | null,
  userId?: string | null,
};

export type ModelApplicationSettingsFilterInput = {
  versionId?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelApplicationSettingsFilterInput | null > | null,
  or?: Array< ModelApplicationSettingsFilterInput | null > | null,
  not?: ModelApplicationSettingsFilterInput | null,
};

export type ModelApplicationSettingsConnection = {
  items:  Array<ApplicationSettings | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelHardwareTemplateFilterInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  versionId?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHardwareTemplateFilterInput | null > | null,
  or?: Array< ModelHardwareTemplateFilterInput | null > | null,
  not?: ModelHardwareTemplateFilterInput | null,
};

export type ModelHardwareTemplateConnection = {
  items:  Array<HardwareTemplate | null >,
  nextToken?: string | null,
};

export type ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyConditionInput = {
  eq?: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  le?: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  lt?: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  ge?: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  gt?: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  between?: Array< ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null > | null,
  beginsWith?: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
};

export type ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyInput = {
  name?: string | null,
  versionId?: string | null,
};

export type ModelHardwareSlotTemplateFilterInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  versionId?: ModelStringInput | null,
  hardwareTemplateName?: ModelStringInput | null,
  hardwareTemplateVersionId?: ModelStringInput | null,
  parentHardwareSlotTemplateName?: ModelStringInput | null,
  parentHardwareSlotTemplateVersionId?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHardwareSlotTemplateFilterInput | null > | null,
  or?: Array< ModelHardwareSlotTemplateFilterInput | null > | null,
  not?: ModelHardwareSlotTemplateFilterInput | null,
};

export type ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyConditionInput = {
  eq?: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  le?: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  lt?: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  ge?: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  gt?: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
  between?: Array< ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null > | null,
  beginsWith?: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput | null,
};

export type ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyInput = {
  name?: string | null,
  versionId?: string | null,
};

export type ModelSiteHardwareConfigurationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  versionId?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  siteCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSiteHardwareConfigurationFilterInput | null > | null,
  or?: Array< ModelSiteHardwareConfigurationFilterInput | null > | null,
  not?: ModelSiteHardwareConfigurationFilterInput | null,
};

export type ModelSiteHardwareConfigurationConnection = {
  items:  Array<SiteHardwareConfiguration | null >,
  nextToken?: string | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyConditionInput = {
  eq?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  le?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  lt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  ge?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  gt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  between?: Array< ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null > | null,
  beginsWith?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput = {
  status?: Status | null,
  versionId?: string | null,
  name?: string | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyConditionInput = {
  eq?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  le?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  lt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  ge?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  gt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  between?: Array< ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput = {
  name?: string | null,
  versionId?: string | null,
  status?: Status | null,
};

export type ModelSubscriptionUserPreferencesFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  preferences?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserPreferencesFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPreferencesFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUserActionFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  actionType?: ModelSubscriptionStringInput | null,
  actionTime?: ModelSubscriptionIntInput | null,
  parameters?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserActionFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserActionFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionApplicationSettingsFilterInput = {
  versionId?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionApplicationSettingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionApplicationSettingsFilterInput | null > | null,
};

export type ModelSubscriptionHardwareTemplateFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  versionId?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHardwareTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionHardwareTemplateFilterInput | null > | null,
};

export type ModelSubscriptionHardwareSlotTemplateFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  versionId?: ModelSubscriptionStringInput | null,
  hardwareTemplateName?: ModelSubscriptionStringInput | null,
  hardwareTemplateVersionId?: ModelSubscriptionStringInput | null,
  parentHardwareSlotTemplateName?: ModelSubscriptionStringInput | null,
  parentHardwareSlotTemplateVersionId?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHardwareSlotTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionHardwareSlotTemplateFilterInput | null > | null,
};

export type ModelSubscriptionSiteHardwareConfigurationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  versionId?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  siteCode?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSiteHardwareConfigurationFilterInput | null > | null,
  or?: Array< ModelSubscriptionSiteHardwareConfigurationFilterInput | null > | null,
};

export type CreateUserPreferencesMutationVariables = {
  input: CreateUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type CreateUserPreferencesMutation = {
  createUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserPreferencesMutationVariables = {
  input: UpdateUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type UpdateUserPreferencesMutation = {
  updateUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserPreferencesMutationVariables = {
  input: DeleteUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type DeleteUserPreferencesMutation = {
  deleteUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: CreateUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserActionMutationVariables = {
  input: UpdateUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type UpdateUserActionMutation = {
  updateUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserActionMutationVariables = {
  input: DeleteUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type DeleteUserActionMutation = {
  deleteUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateApplicationSettingsMutationVariables = {
  input: CreateApplicationSettingsInput,
  condition?: ModelApplicationSettingsConditionInput | null,
};

export type CreateApplicationSettingsMutation = {
  createApplicationSettings?:  {
    versionId: string,
    hardwareTypes:  Array< {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    } | null >,
    slotTypes:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    pacs:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateApplicationSettingsMutationVariables = {
  input: UpdateApplicationSettingsInput,
  condition?: ModelApplicationSettingsConditionInput | null,
};

export type UpdateApplicationSettingsMutation = {
  updateApplicationSettings?:  {
    versionId: string,
    hardwareTypes:  Array< {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    } | null >,
    slotTypes:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    pacs:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteApplicationSettingsMutationVariables = {
  input: DeleteApplicationSettingsInput,
  condition?: ModelApplicationSettingsConditionInput | null,
};

export type DeleteApplicationSettingsMutation = {
  deleteApplicationSettings?:  {
    versionId: string,
    hardwareTypes:  Array< {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    } | null >,
    slotTypes:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    pacs:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHardwareTemplateMutationVariables = {
  input: CreateHardwareTemplateInput,
  condition?: ModelHardwareTemplateConditionInput | null,
};

export type CreateHardwareTemplateMutation = {
  createHardwareTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareType:  {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    },
    hardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceSettings?:  {
      key: string,
      value: string,
      uiAttributes?:  Array< {
        name: string,
        value: string,
      } | null > | null,
    } | null,
    boardSettings?:  {
      columnSpan: number,
      columnOffset?: string | null,
      rowSpan: number,
      sequence?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHardwareTemplateMutationVariables = {
  input: UpdateHardwareTemplateInput,
  condition?: ModelHardwareTemplateConditionInput | null,
};

export type UpdateHardwareTemplateMutation = {
  updateHardwareTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareType:  {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    },
    hardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceSettings?:  {
      key: string,
      value: string,
      uiAttributes?:  Array< {
        name: string,
        value: string,
      } | null > | null,
    } | null,
    boardSettings?:  {
      columnSpan: number,
      columnOffset?: string | null,
      rowSpan: number,
      sequence?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHardwareTemplateMutationVariables = {
  input: DeleteHardwareTemplateInput,
  condition?: ModelHardwareTemplateConditionInput | null,
};

export type DeleteHardwareTemplateMutation = {
  deleteHardwareTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareType:  {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    },
    hardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceSettings?:  {
      key: string,
      value: string,
      uiAttributes?:  Array< {
        name: string,
        value: string,
      } | null > | null,
    } | null,
    boardSettings?:  {
      columnSpan: number,
      columnOffset?: string | null,
      rowSpan: number,
      sequence?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHardwareSlotTemplateMutationVariables = {
  input: CreateHardwareSlotTemplateInput,
  condition?: ModelHardwareSlotTemplateConditionInput | null,
};

export type CreateHardwareSlotTemplateMutation = {
  createHardwareSlotTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareTemplateName: string,
    hardwareTemplateVersionId: string,
    hardwareTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentHardwareSlotTemplateName?: string | null,
    parentHardwareSlotTemplateVersionId?: string | null,
    parentHardwareSlotTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    childHardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHardwareSlotTemplateMutationVariables = {
  input: UpdateHardwareSlotTemplateInput,
  condition?: ModelHardwareSlotTemplateConditionInput | null,
};

export type UpdateHardwareSlotTemplateMutation = {
  updateHardwareSlotTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareTemplateName: string,
    hardwareTemplateVersionId: string,
    hardwareTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentHardwareSlotTemplateName?: string | null,
    parentHardwareSlotTemplateVersionId?: string | null,
    parentHardwareSlotTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    childHardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHardwareSlotTemplateMutationVariables = {
  input: DeleteHardwareSlotTemplateInput,
  condition?: ModelHardwareSlotTemplateConditionInput | null,
};

export type DeleteHardwareSlotTemplateMutation = {
  deleteHardwareSlotTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareTemplateName: string,
    hardwareTemplateVersionId: string,
    hardwareTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentHardwareSlotTemplateName?: string | null,
    parentHardwareSlotTemplateVersionId?: string | null,
    parentHardwareSlotTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    childHardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSiteHardwareConfigurationMutationVariables = {
  input: CreateSiteHardwareConfigurationInput,
  condition?: ModelSiteHardwareConfigurationConditionInput | null,
};

export type CreateSiteHardwareConfigurationMutation = {
  createSiteHardwareConfiguration?:  {
    id: string,
    name: string,
    versionId: string,
    description: string,
    status: Status,
    siteCode: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTypeName: string,
      hardwareDeviceName: string,
      deviceSettings:  Array< {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null >,
      boardSettings:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      },
      slots?:  Array< {
        name: string,
        description: string,
        type: string,
        slotSettings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSiteHardwareConfigurationMutationVariables = {
  input: UpdateSiteHardwareConfigurationInput,
  condition?: ModelSiteHardwareConfigurationConditionInput | null,
};

export type UpdateSiteHardwareConfigurationMutation = {
  updateSiteHardwareConfiguration?:  {
    id: string,
    name: string,
    versionId: string,
    description: string,
    status: Status,
    siteCode: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTypeName: string,
      hardwareDeviceName: string,
      deviceSettings:  Array< {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null >,
      boardSettings:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      },
      slots?:  Array< {
        name: string,
        description: string,
        type: string,
        slotSettings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSiteHardwareConfigurationMutationVariables = {
  input: DeleteSiteHardwareConfigurationInput,
  condition?: ModelSiteHardwareConfigurationConditionInput | null,
};

export type DeleteSiteHardwareConfigurationMutation = {
  deleteSiteHardwareConfiguration?:  {
    id: string,
    name: string,
    versionId: string,
    description: string,
    status: Status,
    siteCode: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTypeName: string,
      hardwareDeviceName: string,
      deviceSettings:  Array< {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null >,
      boardSettings:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      },
      slots?:  Array< {
        name: string,
        description: string,
        type: string,
        slotSettings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetSesamePermissionsByPersonIDQueryVariables = {
  method: string,
  personID: string,
};

export type GetSesamePermissionsByPersonIDQuery = {
  GetSesamePermissionsByPersonID?: string | null,
};

export type GetUserPreferencesQueryVariables = {
  userId: string,
};

export type GetUserPreferencesQuery = {
  getUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserPreferencesQueryVariables = {
  userId?: string | null,
  filter?: ModelUserPreferencesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserPreferencesQuery = {
  listUserPreferences?:  {
    items:  Array< {
      userId: string,
      preferences: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserActionQueryVariables = {
  userId: string,
  actionType: string,
  actionTime: number,
};

export type GetUserActionQuery = {
  getUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserActionsQueryVariables = {
  userId?: string | null,
  actionTypeActionTime?: ModelUserActionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserActionsQuery = {
  listUserActions?:  {
    items:  Array< {
      userId: string,
      actionType: string,
      actionTime: number,
      parameters?: string | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserActionByActionTypeAndActionTimeAndUserIdQueryVariables = {
  actionType: string,
  actionTimeUserId?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActionByActionTypeAndActionTimeAndUserIdQuery = {
  listUserActionByActionTypeAndActionTimeAndUserId?:  {
    items:  Array< {
      userId: string,
      actionType: string,
      actionTime: number,
      parameters?: string | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserActionByActionTimeAndActionTypeAndUserIdQueryVariables = {
  actionTime: number,
  actionTypeUserId?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActionByActionTimeAndActionTypeAndUserIdQuery = {
  listUserActionByActionTimeAndActionTypeAndUserId?:  {
    items:  Array< {
      userId: string,
      actionType: string,
      actionTime: number,
      parameters?: string | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetApplicationSettingsQueryVariables = {
  versionId: string,
};

export type GetApplicationSettingsQuery = {
  getApplicationSettings?:  {
    versionId: string,
    hardwareTypes:  Array< {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    } | null >,
    slotTypes:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    pacs:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListApplicationSettingsQueryVariables = {
  versionId?: string | null,
  filter?: ModelApplicationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListApplicationSettingsQuery = {
  listApplicationSettings?:  {
    items:  Array< {
      versionId: string,
      hardwareTypes:  Array< {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      } | null >,
      slotTypes:  Array< {
        name: string,
        description: string,
        settings?: string | null,
      } | null >,
      pacs:  Array< {
        name: string,
        description: string,
        settings?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHardwareTemplateQueryVariables = {
  name: string,
  versionId: string,
};

export type GetHardwareTemplateQuery = {
  getHardwareTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareType:  {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    },
    hardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceSettings?:  {
      key: string,
      value: string,
      uiAttributes?:  Array< {
        name: string,
        value: string,
      } | null > | null,
    } | null,
    boardSettings?:  {
      columnSpan: number,
      columnOffset?: string | null,
      rowSpan: number,
      sequence?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHardwareTemplatesQueryVariables = {
  name?: string | null,
  versionId?: ModelStringKeyConditionInput | null,
  filter?: ModelHardwareTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHardwareTemplatesQuery = {
  listHardwareTemplates?:  {
    items:  Array< {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHardwareTemplateByStatusAndNameAndVersionIdQueryVariables = {
  status: Status,
  nameVersionId?: ModelHardwareTemplateHardwareTemplatesByStatusAndNameAndVersionIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHardwareTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHardwareTemplateByStatusAndNameAndVersionIdQuery = {
  listHardwareTemplateByStatusAndNameAndVersionId?:  {
    items:  Array< {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHardwareSlotTemplateQueryVariables = {
  name: string,
  versionId: string,
};

export type GetHardwareSlotTemplateQuery = {
  getHardwareSlotTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareTemplateName: string,
    hardwareTemplateVersionId: string,
    hardwareTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentHardwareSlotTemplateName?: string | null,
    parentHardwareSlotTemplateVersionId?: string | null,
    parentHardwareSlotTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    childHardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHardwareSlotTemplatesQueryVariables = {
  name?: string | null,
  versionId?: ModelStringKeyConditionInput | null,
  filter?: ModelHardwareSlotTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHardwareSlotTemplatesQuery = {
  listHardwareSlotTemplates?:  {
    items:  Array< {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHardwareSlotTemplateByStatusAndNameAndVersionIdQueryVariables = {
  status: Status,
  nameVersionId?: ModelHardwareSlotTemplateHardwareSlotTemplatesByStatusAndNameAndVersionIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHardwareSlotTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHardwareSlotTemplateByStatusAndNameAndVersionIdQuery = {
  listHardwareSlotTemplateByStatusAndNameAndVersionId?:  {
    items:  Array< {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSiteHardwareConfigurationQueryVariables = {
  id: string,
};

export type GetSiteHardwareConfigurationQuery = {
  getSiteHardwareConfiguration?:  {
    id: string,
    name: string,
    versionId: string,
    description: string,
    status: Status,
    siteCode: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTypeName: string,
      hardwareDeviceName: string,
      deviceSettings:  Array< {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null >,
      boardSettings:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      },
      slots?:  Array< {
        name: string,
        description: string,
        type: string,
        slotSettings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSiteHardwareConfigurationsQueryVariables = {
  id?: string | null,
  filter?: ModelSiteHardwareConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSiteHardwareConfigurationsQuery = {
  listSiteHardwareConfigurations?:  {
    items:  Array< {
      id: string,
      name: string,
      versionId: string,
      description: string,
      status: Status,
      siteCode: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTypeName: string,
        hardwareDeviceName: string,
        deviceSettings:  Array< {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null >,
        boardSettings:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        },
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
          slotSettings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQueryVariables = {
  siteCode: string,
  statusVersionIdName?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSiteHardwareConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery = {
  listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName?:  {
    items:  Array< {
      id: string,
      name: string,
      versionId: string,
      description: string,
      status: Status,
      siteCode: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTypeName: string,
        hardwareDeviceName: string,
        deviceSettings:  Array< {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null >,
        boardSettings:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        },
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
          slotSettings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQueryVariables = {
  siteCode: string,
  nameVersionIdStatus?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSiteHardwareConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQuery = {
  listSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus?:  {
    items:  Array< {
      id: string,
      name: string,
      versionId: string,
      description: string,
      status: Status,
      siteCode: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTypeName: string,
        hardwareDeviceName: string,
        deviceSettings:  Array< {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null >,
        boardSettings:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        },
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
          slotSettings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserPreferencesSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferencesFilterInput | null,
};

export type OnCreateUserPreferencesSubscription = {
  onCreateUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserPreferencesSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferencesFilterInput | null,
};

export type OnUpdateUserPreferencesSubscription = {
  onUpdateUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserPreferencesSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferencesFilterInput | null,
};

export type OnDeleteUserPreferencesSubscription = {
  onDeleteUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserActionSubscriptionVariables = {
  filter?: ModelSubscriptionUserActionFilterInput | null,
};

export type OnCreateUserActionSubscription = {
  onCreateUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserActionSubscriptionVariables = {
  filter?: ModelSubscriptionUserActionFilterInput | null,
};

export type OnUpdateUserActionSubscription = {
  onUpdateUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserActionSubscriptionVariables = {
  filter?: ModelSubscriptionUserActionFilterInput | null,
};

export type OnDeleteUserActionSubscription = {
  onDeleteUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateApplicationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionApplicationSettingsFilterInput | null,
};

export type OnCreateApplicationSettingsSubscription = {
  onCreateApplicationSettings?:  {
    versionId: string,
    hardwareTypes:  Array< {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    } | null >,
    slotTypes:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    pacs:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateApplicationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionApplicationSettingsFilterInput | null,
};

export type OnUpdateApplicationSettingsSubscription = {
  onUpdateApplicationSettings?:  {
    versionId: string,
    hardwareTypes:  Array< {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    } | null >,
    slotTypes:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    pacs:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteApplicationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionApplicationSettingsFilterInput | null,
};

export type OnDeleteApplicationSettingsSubscription = {
  onDeleteApplicationSettings?:  {
    versionId: string,
    hardwareTypes:  Array< {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    } | null >,
    slotTypes:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    pacs:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateHardwareTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareTemplateFilterInput | null,
};

export type OnCreateHardwareTemplateSubscription = {
  onCreateHardwareTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareType:  {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    },
    hardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceSettings?:  {
      key: string,
      value: string,
      uiAttributes?:  Array< {
        name: string,
        value: string,
      } | null > | null,
    } | null,
    boardSettings?:  {
      columnSpan: number,
      columnOffset?: string | null,
      rowSpan: number,
      sequence?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateHardwareTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareTemplateFilterInput | null,
};

export type OnUpdateHardwareTemplateSubscription = {
  onUpdateHardwareTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareType:  {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    },
    hardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceSettings?:  {
      key: string,
      value: string,
      uiAttributes?:  Array< {
        name: string,
        value: string,
      } | null > | null,
    } | null,
    boardSettings?:  {
      columnSpan: number,
      columnOffset?: string | null,
      rowSpan: number,
      sequence?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteHardwareTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareTemplateFilterInput | null,
};

export type OnDeleteHardwareTemplateSubscription = {
  onDeleteHardwareTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareType:  {
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          name: string,
          description: string,
        } > | null,
        slots?:  Array< {
          name: string,
          description: string,
          type: string,
        } > | null,
      } | null > | null,
      settings?: string | null,
    },
    hardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceSettings?:  {
      key: string,
      value: string,
      uiAttributes?:  Array< {
        name: string,
        value: string,
      } | null > | null,
    } | null,
    boardSettings?:  {
      columnSpan: number,
      columnOffset?: string | null,
      rowSpan: number,
      sequence?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateHardwareSlotTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareSlotTemplateFilterInput | null,
};

export type OnCreateHardwareSlotTemplateSubscription = {
  onCreateHardwareSlotTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareTemplateName: string,
    hardwareTemplateVersionId: string,
    hardwareTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentHardwareSlotTemplateName?: string | null,
    parentHardwareSlotTemplateVersionId?: string | null,
    parentHardwareSlotTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    childHardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateHardwareSlotTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareSlotTemplateFilterInput | null,
};

export type OnUpdateHardwareSlotTemplateSubscription = {
  onUpdateHardwareSlotTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareTemplateName: string,
    hardwareTemplateVersionId: string,
    hardwareTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentHardwareSlotTemplateName?: string | null,
    parentHardwareSlotTemplateVersionId?: string | null,
    parentHardwareSlotTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    childHardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteHardwareSlotTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareSlotTemplateFilterInput | null,
};

export type OnDeleteHardwareSlotTemplateSubscription = {
  onDeleteHardwareSlotTemplate?:  {
    name: string,
    description: string,
    status: Status,
    versionId: string,
    hardwareTemplateName: string,
    hardwareTemplateVersionId: string,
    hardwareTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareType:  {
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            name: string,
            description: string,
          } > | null,
          slots?:  Array< {
            name: string,
            description: string,
            type: string,
          } > | null,
        } | null > | null,
        settings?: string | null,
      },
      hardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceSettings?:  {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null,
      boardSettings?:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentHardwareSlotTemplateName?: string | null,
    parentHardwareSlotTemplateVersionId?: string | null,
    parentHardwareSlotTemplate?:  {
      name: string,
      description: string,
      status: Status,
      versionId: string,
      hardwareTemplateName: string,
      hardwareTemplateVersionId: string,
      hardwareTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareType:  {
          name: string,
          description: string,
          hardwareDevices?:  Array< {
            name: string,
            description: string,
            fields?:  Array< {
              name: string,
            } > | null,
            rules?:  Array< {
              name: string,
              description: string,
            } > | null,
            slots?:  Array< {
              name: string,
              description: string,
              type: string,
            } > | null,
          } | null > | null,
          settings?: string | null,
        },
        hardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceSettings?:  {
          key: string,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } | null,
        boardSettings?:  {
          columnSpan: number,
          columnOffset?: string | null,
          rowSpan: number,
          sequence?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentHardwareSlotTemplateName?: string | null,
      parentHardwareSlotTemplateVersionId?: string | null,
      parentHardwareSlotTemplate?:  {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      childHardwareSlotTemplates?:  {
        items:  Array< {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    childHardwareSlotTemplates?:  {
      items:  Array< {
        name: string,
        description: string,
        status: Status,
        versionId: string,
        hardwareTemplateName: string,
        hardwareTemplateVersionId: string,
        hardwareTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareType:  {
            name: string,
            description: string,
            hardwareDevices?:  Array< {
              name: string,
              description: string,
            } | null > | null,
            settings?: string | null,
          },
          hardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceSettings?:  {
            key: string,
            value: string,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } | null,
          boardSettings?:  {
            columnSpan: number,
            columnOffset?: string | null,
            rowSpan: number,
            sequence?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        parentHardwareSlotTemplateName?: string | null,
        parentHardwareSlotTemplateVersionId?: string | null,
        parentHardwareSlotTemplate?:  {
          name: string,
          description: string,
          status: Status,
          versionId: string,
          hardwareTemplateName: string,
          hardwareTemplateVersionId: string,
          hardwareTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareType:  {
              name: string,
              description: string,
              settings?: string | null,
            },
            hardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            deviceSettings?:  {
              key: string,
              value: string,
            } | null,
            boardSettings?:  {
              columnSpan: number,
              columnOffset?: string | null,
              rowSpan: number,
              sequence?: number | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          parentHardwareSlotTemplateName?: string | null,
          parentHardwareSlotTemplateVersionId?: string | null,
          parentHardwareSlotTemplate?:  {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          childHardwareSlotTemplates?:  {
            items:  Array< {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        childHardwareSlotTemplates?:  {
          items:  Array< {
            name: string,
            description: string,
            status: Status,
            versionId: string,
            hardwareTemplateName: string,
            hardwareTemplateVersionId: string,
            hardwareTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            parentHardwareSlotTemplateName?: string | null,
            parentHardwareSlotTemplateVersionId?: string | null,
            parentHardwareSlotTemplate?:  {
              name: string,
              description: string,
              status: Status,
              versionId: string,
              hardwareTemplateName: string,
              hardwareTemplateVersionId: string,
              parentHardwareSlotTemplateName?: string | null,
              parentHardwareSlotTemplateVersionId?: string | null,
              settings?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            childHardwareSlotTemplates?:  {
              nextToken?: string | null,
            } | null,
            settings?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSiteHardwareConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionSiteHardwareConfigurationFilterInput | null,
};

export type OnCreateSiteHardwareConfigurationSubscription = {
  onCreateSiteHardwareConfiguration?:  {
    id: string,
    name: string,
    versionId: string,
    description: string,
    status: Status,
    siteCode: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTypeName: string,
      hardwareDeviceName: string,
      deviceSettings:  Array< {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null >,
      boardSettings:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      },
      slots?:  Array< {
        name: string,
        description: string,
        type: string,
        slotSettings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSiteHardwareConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionSiteHardwareConfigurationFilterInput | null,
};

export type OnUpdateSiteHardwareConfigurationSubscription = {
  onUpdateSiteHardwareConfiguration?:  {
    id: string,
    name: string,
    versionId: string,
    description: string,
    status: Status,
    siteCode: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTypeName: string,
      hardwareDeviceName: string,
      deviceSettings:  Array< {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null >,
      boardSettings:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      },
      slots?:  Array< {
        name: string,
        description: string,
        type: string,
        slotSettings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSiteHardwareConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionSiteHardwareConfigurationFilterInput | null,
};

export type OnDeleteSiteHardwareConfigurationSubscription = {
  onDeleteSiteHardwareConfiguration?:  {
    id: string,
    name: string,
    versionId: string,
    description: string,
    status: Status,
    siteCode: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTypeName: string,
      hardwareDeviceName: string,
      deviceSettings:  Array< {
        key: string,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } | null >,
      boardSettings:  {
        columnSpan: number,
        columnOffset?: string | null,
        rowSpan: number,
        sequence?: number | null,
      },
      slots?:  Array< {
        name: string,
        description: string,
        type: string,
        slotSettings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
