import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from '../App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { debug } from 'src/utils';

import 'src/styles/temp.css'

const queryClient = new QueryClient();

export default function AuthorizeUser(props: {isAdmin: boolean; employeeId: string; username: string}) {
  debug(`AuthorizeUser(): props is ${JSON.stringify(props)}`);

  return(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/documents/:siteCode'
            element={
              <App
                isAdmin={props.isAdmin}
                employeeId={props.employeeId}
                username={props.username}
              />
            }
          />
          <Route
            path='/documents/:siteCode/:documentId'
            element={
              <App
                isAdmin={props.isAdmin}
                employeeId={props.employeeId}
                username={props.username}
              />
            }
          />
          <Route
            path='/reviews/:siteCode'
            element={
              <App
                isAdmin={props.isAdmin}
                employeeId={props.employeeId}
                username={props.username}
              />
            }
          />
          <Route
            path='/templates/:siteCode'
            element={
              <App
                isAdmin={props.isAdmin}
                employeeId={props.employeeId}
                username={props.username}
              />
            }
          />
          <Route
            path='*'
            element={
              <App
                isAdmin={props.isAdmin}
                employeeId={props.employeeId}
                username={props.username}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
