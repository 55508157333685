import { QueryKey } from "../constants";
import { querySiteHardwareConfigurations } from "src/components/Documents/utils";
import { useQuery } from "@tanstack/react-query";
import { Status } from "src/API";

export function useSiteHardwareConfigurations(siteCode: string, status: Status | string) {
  return useQuery({
    enabled: (siteCode !== ''),
    queryKey: [QueryKey.siteHardwareConfigurations],
    queryFn: () => querySiteHardwareConfigurations(siteCode, status),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
    staleTime: Infinity,
  });
}
