import { LocalizationContextBuilder } from '@amzn/arb-tools';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';

export const BLANK = '&#8203;';

export const DefaultValidFromTime = '08:00';
export const DefaultValidThroughTime = '17:00';

export const HOW_MANY_TRACKER = {
  WebsiteAccess_URL_BETA: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=5bfcb583-f45c-fda5-ccec-42b4c7d9a11e',
  WebsiteAccess_URL_GAMMA: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=63cf9cb7-e8af-93b2-298d-a7bd7153cdef',
  WebsiteAccess_URL_PROD: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=15fce6bb-6d0a-5f14-52ef-2a50ab2061bf',
};

export enum Images {
  home = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAIRlWElmTU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAABIAAAAAQAAAEgAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAABigAwAEAAAAAQAAABgAAAAAEQ8YrgAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDYuMC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KGV7hBwAAAQ9JREFUSA3NVO0NgyAQlaYDdARH6gjdpCM4QkdxhI5QN7D/DGhK7xmfAaMGLCQ1MXcHd+8+eFAU//wNWl+N1q/eGIsfOtaS1AwgAi9lkiSs3BjzBCB+6Ozkpy6stRdW7VYLnetMcKKSS55jgbuuK4e+fzDuo9RdKh9N6Eo06bDhfrCcxlJxBHvSHZuXABs8PACQdnJ4N7FbB7TF2pa/B0oDzg7AyOsNu0I3jAuWrHyNdlOiGvMPBnQdY2jnxoXo2Wk6F5F1RMiS/ZCZhJ3gYJPSdJ7VhpLkom1ge8ugqnRX846s0Rqde0GxRgyts9M0+jVFt0qpt4yhEVkme02XY9yjNfaW/odsAK3R+hDY0aAvQGiV1iDEGEYAAAAASUVORK5CYII=',
}

export interface ILanguage {
  id: string;
  text: string;
}

export const Languages: ILanguage[] = [
  {
    id: 'en-US',
    text: 'English',
  },
  {
    id: 'fr-FR',
    text: 'French',
  },
  {
    id: 'de-DE',
    text: 'German',
  },
  {
    id: 'es-ES',
    text: 'Spanish',
  },
  {
    id: 'pt-PT',
    text: 'Portuguese',
  },
  {
    id: 'it-IT',
    text: 'Italian',
  },
  {
    id: 'hi-IN',
    text: 'Hindi',
  },
  {
    id: 'ja-JP',
    text: 'Japanese',
  },
  {
    id: 'ar-AE',
    text: 'Arabic (U.A.E.)',
  },
  {
    id: 'ar-SA',
    text: 'Arabic (Saudi Arabia)',
  },
  {
    id: 'cs-CZ',
    text: 'Czech (Czech Republic)',
  },
  {
    id: 'en-AU',
    text: 'English (Australia)',
  },
  {
    id: 'en-CA',
    text: 'English (Canada)',
  },
  {
    id: 'en-GB',
    text: 'English (United Kingdom)',
  },
  {
    id: 'en-SG',
    text: 'English (Singapore)',
  },
  {
    id: 'es-MX',
    text: 'Spanish (Mexico)',
  },
  {
    id: 'fr-CA',
    text: 'French (Canada)',
  },
  {
    id: 'nl-NL',
    text: 'Dutch (Netherlands)',
  },
  {
    id: 'pl-PL',
    text: 'Polish (Poland)',
  },
  {
    id: 'sk-SK',
    text: 'Slovak (Slovakia)',
  },
  {
    id: 'tr-TR',
    text: 'Turkish (Turkey)',
  },
  {
    id: 'zh-CN',
    text: 'Chinese (S)',
  },
  {
    id: 'zh-TW',
    text: 'Chinese (T)',
  },
];

export enum SPOTApplicationSettings {
}

export const ProdDomain = 'spot.gso.amazon.dev';

export const Regions = ['AMER','APAC','EMEA'];

export const URLS = {
  AmazonVisits: 'https://visits.acs.amazon.dev/',
  Contact: 'https://w.amazon.com/index.php/SIDE#HContactus',
  FeatureRequest: 'https://w.amazon.com/bin/view/AccSys/SPOT/#HRequestafeature',
  GSOGlobalSecuritySystemStandard: 'https://policy.fintech.amazon.dev/docs/68400/publication#11',
  GSOSecuritySystemsStandardGlobal: 'https://policy.a2z.com/docs/68400/publication',
  GSOVisitorStandardGlobal: 'https://policy.a2z.com/docs/25853/publication',
  GuardianHub: 'https://guardian.gso.amazon.dev/',
  ReportABug: 'https://t.corp.amazon.com/create/templates/5b7b9362-5e38-428a-b0d3-faa1446bc04e',
  UserGuide: 'https://w.amazon.com/bin/view/AccSys/SPOT/',
  VendorDayPassDeprecationWiki: 'https://w.amazon.com/bin/view/AccSys/SPOT/VendorDayPassDeprecation/',
};
