import {
  AppLayout,
  Modal,
  SelectProps,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  CreateUserPreferencesInput,
  SiteHardwareConfiguration,
  UpdateUserPreferencesInput,
} from 'src/API';
import { DEFAULT_LC_BUILDER } from 'src/constants';
import {
  DEFAULT_USER_PREFERENCES,
  Page,
  Pages,
} from './common/constants';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Documents from './Documents/Documents';
import Reviews from './Reviews/Reviews';
import SelectSite from './common/SelectSite';
import SideNav from './common/SideNav';
import Templates from './Templates/Templates';
import TopNav from './common/TopNav';
import Tracker from './common/Tracker';
import i18n from 'src/i18n';
import { debug } from 'src/utils';
import {
  createUserPreferences,
  updateUserPreferences,
} from './utils';
import { useAllApplicationSettings } from './common/hooks/useAllApplicationSettings';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import { useEffect, useState } from 'react';
import { useRum } from 'src/hooks/useRum';
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import { useSelectedApplicationSettings } from './common/hooks/useSelectedApplicationSettings';
import { useUserPreferences } from './common/hooks/useUserPreferences';

export let debugLogging: boolean = false;

export interface IAppProps {
  isAdmin: boolean;
  employeeId: string;
  username: string;
}

export default function App(props: IAppProps) {
  debug(`App() props is ${JSON.stringify(props)}`);

  const { siteCode, documentId } = useParams();

  debug(`App() siteCode url param is ${siteCode}`);
  debug(`App() documentId url param is ${documentId}`);

  const userPreferencesQuery = useUserPreferences(props.employeeId);
  const allApplicationSettingsQuery = useAllApplicationSettings();

  const latestApplicationSettingsVersion = (): SelectProps.Option | undefined => {
    debug(`App() latestApplicationSettings() allApplicationSettingsQuery.data is ${JSON.stringify(allApplicationSettingsQuery.data)}`);
    const allApplicationSettings = allApplicationSettingsQuery.data;
    if (allApplicationSettings === undefined || allApplicationSettings.length === 0) return;
    const latestApplicationSettingsVersion = allApplicationSettings?.reduce((prev: any, current: any) => {
      return (prev.versionId > current.versionId) ? prev : current;
    });
    const latestApplicationSettingsOption: SelectProps.Option = {
      label: latestApplicationSettingsVersion?.versionId,
      value: latestApplicationSettingsVersion?.versionId,
    };
    debug(`App() latestApplicationSettings() latestApplicationSettingsOption is ${JSON.stringify(latestApplicationSettingsOption)}`);
    return(latestApplicationSettingsOption);
  };

  const [activeHref, setActiveHref] = useState<string>('/documents/');
  const [currentEditDocumentId, setCurrentEditDocumentId] = useState<string | null>(null);
  const [enableDebugLogging, setEnableDebugLogging] = useState<boolean>(window.location.hostname === 'localhost.a2z.com');
  const [initialPage, setInitialPage] = useState<string | null>(null);
  const [navigationOpen, setNavigationOpen] = useState<boolean>(true);
  const [openDocuments, setOpenDocuments] = useState<SiteHardwareConfiguration[]>([]);
  const [selectedApplicationSettingsVersion, setSelectedApplicationSettingsVersion] = useState<SelectProps.Option | undefined>(latestApplicationSettingsVersion());
  const [selectedSiteCode, setSelectSiteCode] = useState<string>(siteCode || '');
  const [showSelectSite, setShowSelectSite] = useState<boolean>(false);

  const selectedApplicationSettingsQuery = useSelectedApplicationSettings(selectedApplicationSettingsVersion?.value!);

  const { localizationContext, setLocalizationContext } = useLocalizationContext();

  useRum(props.employeeId);

  debugLogging = enableDebugLogging;

  const [bundle, isBundleLoading] = useBundle('components.App');

  const saveUserPrefsMutation = useMutation({
    mutationFn: async (input: CreateUserPreferencesInput) => {
      await createUserPreferences(input);
      await userPreferencesQuery.refetch();
    },
  });

  const siteSelected = async (siteCode: string) => {
    if (selectedSiteCode === siteCode) return;
    setSelectSiteCode(siteCode);
    if (!userPreferencesQuery?.data?.preferences) {
      const createUserPreferencesInput: CreateUserPreferencesInput = {
        preferences: JSON.stringify({
          ...DEFAULT_USER_PREFERENCES,
          lastSiteCode: siteCode,
        }),
        userId: props.employeeId,
      };
      await saveUserPrefsMutation.mutateAsync(createUserPreferencesInput);
    } else {
      const userPreferences = JSON.parse(userPreferencesQuery?.data?.preferences);
      userPreferences.lastSiteCode = siteCode;
      const updateUserPreferencesInput: UpdateUserPreferencesInput = {
        userId: props.employeeId,
        preferences: JSON.stringify(userPreferences),
      };
      await updateUserPreferences(updateUserPreferencesInput);
    }
    setShowSelectSite(false);
  };

  const addOpenDocument = (document: SiteHardwareConfiguration) => {
    setActiveHref(`/documents/${selectedSiteCode}/${document.id}`);
    if (openDocuments.some(d => d.id === document.id)) return;
    setOpenDocuments([
      ...openDocuments,
      document,
    ]);
  };

  const removeOpenDocument = (document: SiteHardwareConfiguration) => {
    const newOpenDocuments = openDocuments.filter(d => d.id !== document.id);
    setOpenDocuments(newOpenDocuments);
    setActiveHref(Pages.find(p => p.code === Page.Documents)!.route);
  };

  useEffect(() => {
    try {
      let userPreferences = null;
      if (userPreferencesQuery?.data?.preferences) userPreferences = JSON.parse(userPreferencesQuery.data.preferences);
      if (userPreferences?.darkMode) {
        document.body.classList.add('awsui-polaris-dark-mode');
      } else {
        document.body.classList.remove('awsui-polaris-dark-mode');
      }
      if (userPreferences?.language) {
        i18n.changeLanguage(userPreferences.language.value);
        setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(userPreferences.language.value).build());
      }
      if (userPreferences?.lastSiteCode) {
        setSelectSiteCode(userPreferences.lastSiteCode);
      }
      if (userPreferences?.initialPage) {
        if (initialPage === null) {
          const userPrefsInitialPage = userPreferences.initialPage || Pages.find(p => p.code === Page.Documents)?.code;
          setInitialPage(userPreferences.initialPage);
          setActiveHref(Pages.find(p => p.code === userPrefsInitialPage)?.route || activeHref);
        }
      }
    } catch(error) {
      console.error(error);
    }
  }, [userPreferencesQuery.data]);

  useEffect(() => {
    selectedApplicationSettingsQuery.refetch();
  }, [selectedApplicationSettingsVersion]);

  useEffect(() => {
    if (!selectedApplicationSettingsVersion) setSelectedApplicationSettingsVersion(latestApplicationSettingsVersion());
  }, [allApplicationSettingsQuery.data]);

  useEffect(() => {
    const activeHrefParams = activeHref.split('/');
    debug(`App() activeHrefParams is ${JSON.stringify(activeHrefParams)}`);
    switch (activeHrefParams[1]) {
      case Page.Documents:
        if (activeHrefParams[3] === null) {
          setCurrentEditDocumentId(null);
        }
        if (activeHrefParams[3] !== null) {
          setCurrentEditDocumentId(activeHrefParams[3]);
        }
        break;
      default:
        break;
    }
  }, [activeHref]);

  if (isBundleLoading || !selectedApplicationSettingsVersion) {
    return(
      <Modal
        header={<>Initializing <Spinner/></>}
        visible
      >
        {isBundleLoading ? <h3>Loading Language...</h3> : <></>}
        {!selectedApplicationSettingsVersion ? <h3>Loading Application Settings...</h3> : <></>}
      </Modal>);
  }

  return (
    <>
      {showSelectSite
      &&
      <SelectSite
        selectedSiteCode={selectedSiteCode}
        setSelectedSiteCallback={siteSelected}
        setSiteSelectVisibleCallback={setShowSelectSite}
      />}
      <div
        className='awsui'
        id='topNavigation'
        style={{position: 'sticky', top: 0, zIndex: 1002}}
      >
        <TopNav
          enableDebugLogging={enableDebugLogging}
          setEnableDebugLoggingCallback={setEnableDebugLogging}
          setShowSelectSite={setShowSelectSite}
          siteCode={selectedSiteCode}
          userId={props.employeeId}
          username={props.username}
        />
      </div>
      <AppLayout
        ariaLabels={{ navigationClose: 'close' }}
        content={
          (activeHref.split('/')[1] === Page.Reviews)
          &&
          <Reviews/>
          ||
          (activeHref.split('/')[1] === Page.Templates)
          &&
          <Templates/>
          ||
          (activeHref.split('/')[1] === Page.Documents)
          &&
          <Documents
            addOpenDocument={addOpenDocument}
            currentEditDocumentId={currentEditDocumentId}
            removeOpenDocument={removeOpenDocument}
            isAdmin={props.isAdmin}
            openDocuments={openDocuments}
            selectedApplicationSettingsVersion={selectedApplicationSettingsVersion!}
            setSelectedApplicationSettingsVersion={setSelectedApplicationSettingsVersion}
            setOpenDocuments={setOpenDocuments}
            siteCode={selectedSiteCode}
            username={props.username}
          />
        }
        contentType='table'
        disableContentPaddings
        headerSelector='#topNavigation'
        navigation={
          <SideNav
            activeHref={activeHref}
            enableDebugLogging={enableDebugLogging}
            isAdmin={props.isAdmin}
            openDocuments={openDocuments
              .map(od => {
                return {
                  id: od.id,
                  name: od.name,
                  versionId: od.versionId
                }})
              }
            siteCode={selectedSiteCode}
            setActiveHref={setActiveHref}
            userId={props.employeeId}
            username={props.username}
          />
        }
        navigationHide={false}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        stickyNotifications
        toolsHide
      />
      <Tracker username={props.username}/>
      <ReactQueryDevtools/>
    </>
  );
}
