import {
  Alert,
  Box,
  Button,
  FormField,
  Modal,
  Select,
  SpaceBetween,
  Spinner,
  Toggle,
  ToggleProps,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useBundle } from "@amzn/react-arb-tools";
import { ILanguage, Languages } from '../../constants';
import * as APIt from '../../API';
import { createUserPreferences, updateUserPreferences } from '../utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { debug } from 'src/utils';
import { Page, Pages, QueryKey } from './constants';

interface IUserPreferencesProps {
  closeUserPreferencesCallback(): void;
  enableDebugLogging: boolean;
  language: ILanguage | undefined;
  setEnableDebugLoggingCallback: Function;
  userId: string;
}

export const UserPreferences = (props: IUserPreferencesProps) => {
  debug(`UserPreferences() props is ${JSON.stringify(props)}`);

  const queryClient = useQueryClient();

  const userPreferencesQuery = useQuery({ queryKey: [QueryKey.userPreferences] });
  debug(`userPreferencesQuery is ${JSON.stringify(userPreferencesQuery)}`);

  const [bundle, isBundleLoading] = useBundle('components.common.UserPreferences');

  const getDarkMode = (): boolean => { 
    if (userPreferencesQuery?.data) {
      const userPreferences: APIt.UserPreferences = userPreferencesQuery?.data as APIt.UserPreferences;
      const preferences = JSON.parse(userPreferences?.preferences || '{}');
      return preferences?.darkMode || false;
    }
    return false;
  };

  const getLanguage = (): { value: string, label: string } | null => {
    const defaultLanguage = {label: 'English', value: 'en-US'};
    if (userPreferencesQuery?.data) {
      const userPreferences: APIt.UserPreferences = userPreferencesQuery?.data as APIt.UserPreferences;
      const preferences = JSON.parse(userPreferences?.preferences || '{}');
      return preferences?.language || defaultLanguage;
    }
    return defaultLanguage;
  };

  const getInitialPage = (): { value: string, label: string } => { 
    if (userPreferencesQuery?.data) {
      const userPreferences: APIt.UserPreferences = userPreferencesQuery?.data as APIt.UserPreferences;
      const preferences = JSON.parse(userPreferences?.preferences || '{}');
      return {
        label: isBundleLoading
          ? preferences?.initialPage || Page.Documents
          : preferences?.initialPage ? bundle.getMessage(preferences?.initialPage) : bundle.getMessage(Page.Documents),
        value: preferences?.initialPage || Page.Documents
      };
    }
    return {
      label: Page.Documents,
      value: Page.Documents
    };
  };

  const [enableDebugLogging, setEnableDebugLogging] = useState<boolean>(props.enableDebugLogging);
  const [selectedDarkMode, setSelectedDarkMode] = useState<boolean>(getDarkMode());
  const [selectedInitialPage, setSelectedInitialPage] = useState< { value: string, label: string } | null >(getInitialPage());
  const [selectedLanguage, setSelectedLanguage] = useState< { value: string, label: string } | null >(getLanguage());

  const darkModeOnChangeHandler = (detail: ToggleProps.ChangeDetail) => {
    setSelectedDarkMode(detail.checked);
  };

  const saveUserPrefs = async () => {
    debug(`UserPreferences() saveUserPrefs() userPreferences?.data is ${JSON.stringify(userPreferencesQuery?.data)}`);
    let userPreferences: APIt.UserPreferences | null = userPreferencesQuery?.data as APIt.UserPreferences;

    if (userPreferencesQuery?.data) {
      const updateUserPreferencesInput: APIt.UpdateUserPreferencesInput = {
        preferences: JSON.stringify({
          ...JSON.parse(userPreferences?.preferences || '{}'),
          darkMode: selectedDarkMode,
          initialPage: selectedInitialPage?.value || undefined,
          language: selectedLanguage,
        }),
        userId: props.userId,
      };
      await updateUserPreferences(updateUserPreferencesInput);
    }

    userPreferences =
      {
        ...JSON.parse(userPreferences?.preferences || '{}'),
        darkMode: selectedDarkMode,
        language: selectedLanguage,
      };

    if (!userPreferencesQuery?.data) {
      const createUserPreferencesInput: APIt.CreateUserPreferencesInput = {
        preferences: JSON.stringify({
          ...JSON.parse(userPreferences?.preferences || '{}'),
          darkMode: selectedDarkMode,
          language: selectedLanguage
        }),
        userId: props.userId,
      };
      await createUserPreferences(createUserPreferencesInput);
    }

    queryClient.invalidateQueries({ queryKey: [QueryKey.userPreferences] })
    props.closeUserPreferencesCallback();
  };

  const saveUserPrefsMutation = useMutation({
    mutationFn: async () => {
      await saveUserPrefs();
      await userPreferencesQuery.refetch();
    },
  });

  useEffect(() => {
    if (!isBundleLoading) {
      setSelectedInitialPage(getInitialPage());
    }
  }, [isBundleLoading]);

  if (isBundleLoading || userPreferencesQuery.isFetching) return <Spinner/>;

  return (
    <Modal
      visible={true}
      header={bundle.getMessage('user-preferences')}
      onDismiss={() => props.closeUserPreferencesCallback()}
      closeAriaLabel='close user preferences'
      size='small'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='primary'
              onClick={() => saveUserPrefsMutation.mutate()}
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size='m' direction='vertical'>
        <FormField label={bundle.getMessage('dark-mode')}>
          <Toggle
            onChange={({ detail }) => darkModeOnChangeHandler(detail)}
            ariaLabel={bundle.getMessage('dark-mode')}
            checked={selectedDarkMode}
          />
        </FormField>
        <FormField label={bundle.getMessage('language')}>
          <Select
            filteringType='auto'
            onChange={({ detail }) => setSelectedLanguage({ value: detail.selectedOption.value!, label: detail.selectedOption.label! })}
            options={Languages.map(l => { return { label: l.text, value: l.id }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)}
            selectedOption={selectedLanguage}
          />
        </FormField>
        <FormField label={bundle.getMessage('initial-page')}>
          <Select
            onChange={({ detail }) => setSelectedInitialPage({ value: detail.selectedOption.value!, label: detail.selectedOption.label! })}
            options={Pages.map(p => { return { label: bundle.getMessage(p.name), value: p.code }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)}
            selectedOption={selectedInitialPage}
          />
        </FormField>
        <hr/>
        <Alert type='warning'>
          The following settings are for testing/support, they are not saved.
        </Alert>
        <FormField label={bundle.getMessage('enable-debug-logging')}>
          <Toggle
            onChange={({ detail }) => {
              setEnableDebugLogging(detail.checked);
              props.setEnableDebugLoggingCallback(detail.checked);
            }}
            ariaLabel='enable debug logging'
            checked={enableDebugLogging}
          >
            Enable Debug Logging
          </Toggle>
        </FormField>
      </SpaceBetween>
    </Modal>
  );
}
