import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { debug } from 'src/utils';

export let awsRum: AwsRum | undefined = undefined;

export function useRum(username: string) {
  if (username && awsRum) {
    awsRum.addSessionAttributes({
      username: username
    });
  }
  try {
    const config: AwsRumConfig = {
      allowCookies: true,
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      guestRoleArn: 'arn:aws:iam::767398104510:role/GSO-SPOT-Website-Unauthenticated-Role-prod',
      enableRumClient: true,
      enableXRay: true,
      identityPoolId: 'us-east-1:f91af51f-a518-4588-8e26-c759ebf61720',
      sessionSampleRate: 1,
      telemetries: ['errors', 'performance', 'http'],
    };

    debug(`useRum() config is ${JSON.stringify(config)}`);

    const APPLICATION_ID = '96bb1677-490a-45a5-9882-ac86b0a71c0e';
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-1';

    debug(`useRum() APPLICATION_ID is ${APPLICATION_ID}`);
    debug(`useRum() APPLICATION_VERSION is ${APPLICATION_VERSION}`);
    debug(`useRum() APPLICATION_REGION is ${APPLICATION_REGION}`);

    if (window.location.hostname.includes('localhost')) {
      return({ awsRum });
    }

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config);

  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization or we might get into a feedback loop
  }

  return({ awsRum });
}
