import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../constants";
import { queryUserPreferences } from "../../utils";

export function useUserPreferences(employeeId: string) {
  return useQuery({
    queryKey: [QueryKey.userPreferences],
    queryFn: async () => {
      return(await queryUserPreferences(employeeId));
    },
    retry: 3,
    staleTime: Infinity,
  });
}
