import { useEffect, useState } from 'react';
import AuthorizeUser from './AuthorizeUser';
import { URLS } from '../../constants';
import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { Box, Link, Modal, Spinner } from '@amzn/awsui-components-react';
import { jwtDecode } from 'jwt-decode';
import { createUserAction } from '../utils';
import { debug } from '../../utils';
import { CreateUserActionInput } from 'src/API';

export var authenticatedUsername: string = '';

const AMPLIFY_SYMBOL = ((typeof Symbol !== 'undefined' && typeof Symbol.for === 'function') ?
  Symbol.for('amplify_default') : '@@amplify_default') as Symbol;

const dispatchAuthEvent = (event:string, data:any, message:string) => {
  Hub.dispatch('auth', { event, data, message }, 'Auth', AMPLIFY_SYMBOL);
};

export default function AuthenticateUser() {
  debug('AuthenticateUser()');

  document.title = 'SPOT';

  const [ authFailed, setAuthFailed ] = useState<boolean>(false);
  const [ authError, setAuthError ] = useState<string>('');
  const [ decodedTokenValue, setDecodedTokenValue ] = useState<any>();
  const [ employeeId, setEmployeeId ] = useState<string>();
  const [ isAdmin, setIsAdmin ] = useState<boolean>(false);
  const [ username, setUsername ] = useState<string>('');

  Hub.listen('auth', ({ payload: { event, data }  }) => {
    debug(`AuthenticateUser() event is ${event} data is ${JSON.stringify(data)}`);
    const userActionInput: CreateUserActionInput = {
      actionTime: Date.now(),
      actionType: 'login',
      ttl: Math.round((Date.now()+10000)/1000),
      userId: authenticatedUsername,
    };
    // createUserAction(userActionInput);
    switch(event) {
      case 'customOAuthState':
        window.location.replace(data);
        break;
    }
    console.log(event);
    switch (event) {
      case "signIn":
        console.log(data);
        let clientLogin =
          data.signInUserSession.idToken.payload.identities[0].userId;
        return;
      case "signIn_failure":
        console.log("the user failed to sign in");
        console.log("the error is", data);
        return;
      default:
        break;
    }
  });

  useEffect(() => {
    debug('AuthenticateUser() useEffect([])');
    setAuthFailed(false);
    Auth.currentAuthenticatedUser()
      .then(async (cognitoUserData: { username: string; signInUserSession: { idToken: { jwtToken: any; }; }; }) => {
        try {
          const userActionInput: CreateUserActionInput = {
            actionTime: Date.now(),
            actionType: 'login',
            ttl: Math.round((Date.now()+10000)/1000),
            userId: authenticatedUsername || 'unknown',
          };
          await createUserAction(userActionInput);
        } catch(error) {
          console.error('AuthenticateUser Auth.currentAuthenticatedUser', error);
        }
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() cognitoUserData is ${JSON.stringify(cognitoUserData)}`);
        const username = cognitoUserData.username.split('_')[1];
        setUsername(username);
        debug(`AuthenticateUser() useEffect([]) currentAuthenticatedUser() username is ${username}`);
        try {
          const jwtToken = cognitoUserData.signInUserSession.idToken.jwtToken;
          const decodedTokenValue: any = jwtDecode(jwtToken);
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
          setDecodedTokenValue(decodedTokenValue);
          let employeeId  = decodedTokenValue['custom:employeeId'];
          if (username == 'svcgsospottest1' || username == 'svcgsospottest2') employeeId = -99;
          setEmployeeId(employeeId);
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() employeeId is ${employeeId}`);
        } catch(error) {
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        }
        dispatchAuthEvent('configured', null, `The Auth category has been configured successfully`);
      })
      .catch((error) => {
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${JSON.stringify(error)}`);
        setAuthError(error);
        Auth.federatedSignIn({ customProvider: 'AmazonFederate', customState: window.location.href })
          .catch(error => {
            debug(`AuthenticateUser() useEffect([]) federatedSignIn() error is ${JSON.stringify(error)}`);
            setAuthFailed(true);
            setAuthError(error);
          });
        const userActionInput: CreateUserActionInput = {
          actionTime: Date.now(),
          actionType: 'login',
          ttl: Math.round((Date.now()+10000)/1000),
          userId: authenticatedUsername || 'unknown',
        };
        createUserAction(userActionInput);
      });
  }, []);

  let componentToRender;

  if (username && employeeId && decodedTokenValue) {
    debug(`AuthenticateUser() authentication complete, username is ${username} employeeId is ${employeeId} decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
    authenticatedUsername = username;
    componentToRender = (
      <div>
        <AuthorizeUser isAdmin={isAdmin} employeeId={employeeId} username={username} />
      </div>
    );
  } else if (authFailed) {
    debug(`AuthenticateUser() authFailed`);
    const userActionInput: CreateUserActionInput = {
      actionTime: Date.now(),
      actionType: 'login',
      ttl: Math.round((Date.now()+10000)/1000),
      userId: authenticatedUsername || 'unknown',
    };
    createUserAction(userActionInput);
    componentToRender = (
      <Modal
        header={<>Authentication Failure</>}
        visible
      >
        Failed to authenticate user, please try again or contact <Link external href={URLS.Contact}>SIDE Support</Link>.
      </Modal>
    );
  } else {
    debug(`AuthenticateUser() authenticating`);
    componentToRender = (
      <Modal
        header={<>Initializing <Spinner/></>}
        visible
      >
        <h3>Authenticating User...</h3>
      </Modal>
    );
  }

  return componentToRender;
}
