import React from 'react';

import './i18n';
const arbManifest = require('../build/i18n/arbManifest');
import { DEFAULT_LOCALE, DEFAULT_LC_BUILDER } from "./constants";
import { MbmProvider } from "@amzn/react-arb-tools";

import { Spinner } from '@amzn/awsui-components-react';
import { createRoot } from 'react-dom/client';

import { Amplify, Auth } from 'aws-amplify';

import AppInitWrapper from './components/AppInitWrapper';
import AuthenticateUser from './components/Auth/AuthenticateUser';

import { debug } from './utils';

import './index.scss';

// @ts-ignore because this is a replacement var
if ('prod'.toLowerCase() !== 'prod') {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

const logger = new Amplify.Logger('test');
debug('AuthenticateUser() Stage is ' + 'prod');
debug('AuthenticateUser() AWS_REGION is ' + 'us-east-1');
debug('AuthenticateUser() AWS_ACCOUNT is ' + '767398104510');
debug('AuthenticateUser() GUEST_ROLE_ARN is ' + 'arn:aws:iam::767398104510:role/GSO-SPOT-Website-Unauthenticated-Role-prod');
debug('AuthenticateUser() IDENTITY_POOL_DOMAIN is ' + 'gso-spot-prod.auth.us-east-1.amazoncognito.com');
debug('AuthenticateUser() IDENTITY_POOL_ID is ' + 'us-east-1:f91af51f-a518-4588-8e26-c759ebf61720');
debug('AuthenticateUser() USER_POOL_ID is ' + 'us-east-1_61RAUZNbs');

const callbackUrl = window.location.origin;
const config = {
  API: {
    graphql_endpoint: 'https://api.prod.spot.gso.amazon.dev/graphql',
    graphql_headers: async () => ({
      Authorization: (await Auth.currentAuthenticatedUser())
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken(),
    }),
  },
  // AppSync
  aws_appsync_graphqlEndpoint: 'https://api.prod.spot.gso.amazon.dev/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // Cognito
  aws_cognito_identity_pool_id: 'us-east-1:f91af51f-a518-4588-8e26-c759ebf61720',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_61RAUZNbs',
  aws_user_pools_web_client_id: '10vcae1r4olc0seudpq9f20avh',
  oauth: {
    domain: 'gso-spot-prod.auth.us-east-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code',
  },
};
Amplify.configure(config);

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE).withDefaultLocale(DEFAULT_LOCALE).build(),
  urlPrefix: '/i18n',
};
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<Spinner size='large' />}>
        <MbmProvider {...mbmOptions}>
          <AuthenticateUser />
        </MbmProvider>
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>
);
