import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { Spinner } from '@amzn/awsui-components-react';
import { Page, Pages } from './constants';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useEffect, useState } from 'react';

interface ISideNav {
  activeHref: string;
  enableDebugLogging: boolean;
  isAdmin: boolean;
  openDocuments: { id: string, name: string, versionId: string }[];
  setActiveHref: Function;
  siteCode: string | undefined;
  userId: string;
  username: string;
}

export default function SideNav(props: ISideNav) {
  debug(`SideNav() props is ${JSON.stringify(props)}`);

  const [activeHref, setActiveHref] = useState<string>(props.activeHref);

  const [bundle, isBundleLoading] = useBundle('components.common.SideNav');

  const menuItems = (): SideNavigationProps.Item[] => {
    const items: SideNavigationProps.Item[] = [];
    items.push({
      type: 'link',
      text: bundle.getMessage(Pages.find(p => p.code === Page.Templates)?.name || ''),
      href: Pages.find(p => p.code === Page.Templates)?.route || '#',
    });
    if (props.openDocuments.length > 0) {
      items.push({
        type: 'link-group',
        text: bundle.getMessage('documents'),
        href: Pages.find(p => p.code === Page.Documents)?.route || '#',
        items: 
          props.openDocuments
            .sort((a,b) => `${a.name.replace(/\s/g, '')}${a.versionId}` < `${b.name.replace(/\s/g, '')}${b.versionId}` ? -1 : 1)
            .map(od => {
              return({
                type: 'link',
              text: `${od.name} ${od.versionId}`,
                href: `/documents/${props.siteCode}/${od.id}`,
              });
            })
      });
    } else {
      items.push({
        type: 'link',
        text: bundle.getMessage(Pages.find(p => p.code === Page.Documents)?.name || ''),
        href: Pages.find(p => p.code === Page.Documents)?.route || '#',
      });
    }
    items.push({
      type: 'link',
      text: bundle.getMessage(Pages.find(p => p.code === Page.Reviews)?.name || ''),
      href: Pages.find(p => p.code === Page.Reviews)?.route || '#',
    });
    return items;
  };

  useEffect(() => {
    setActiveHref(props.activeHref);
    props.setActiveHref(props.activeHref);
  }, [props.activeHref])

  if (isBundleLoading) return <Spinner/>;

  return (
    <SideNavigation
      header={{
        href: '#',
        text: '',
      }}
      activeHref={activeHref}
      items={menuItems()}
      onFollow={event => {
        if (!event.detail.external) {
          event.preventDefault();
          props.setActiveHref(event.detail.href);
        }
      }}
    />
  );
}
