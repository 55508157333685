import { SiteHardwareConfiguration, Status } from 'src/API';
import {
  Alert,
  Box,
  Button,
  CollectionPreferences,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter, 
 } from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  PaginationLabels,
  TableEmptyState,
  TableNoMatchState,
} from './table-config';
import Header from '@amzn/awsui-components-react/polaris/header';
import { UseQueryResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { debug } from 'src/utils';
import { deleteSiteHardwareConfiguration } from './utils';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useBundle } from '@amzn/react-arb-tools';
import { useSiteHardwareConfigurations } from '../common/hooks/useSiteHardwareConfigurations';
import { useState, useEffect } from 'react';

export interface IDocumentsTablePanelProps {
  admin: boolean;
  createNewDocument: Function;
  createNewDocumentRevision: Function;
  editDocument: Function;
  selectedDocument: SiteHardwareConfiguration | null;
  removeFromOpenDocuments: Function;
  setSelectedDocument: Function;
  siteCode: string;
  siteHardwareConfigurationsQuery: UseQueryResult<SiteHardwareConfiguration[]>;
}

export function DocumentsTablePanel(props: IDocumentsTablePanelProps ) {
  debug(`DocumentsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<SiteHardwareConfiguration[]>(props.siteHardwareConfigurationsQuery.data || []);
  const [error, setError] = useState<string>();
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [pageSize, setPageSize ] = useState<number>(5);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<SiteHardwareConfiguration | null>(props.selectedDocument);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const siteHardwareConfigurationsQuery = useSiteHardwareConfigurations(props.siteCode, Status.Development);

  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentsTablePanel');

  const queryClient = useQueryClient();

  const deleteSiteHardwareConfigurationMutation = useMutation(
    {
      mutationFn: deleteSiteHardwareConfiguration,
      onSuccess: (data) => {
        setAllItems([]);
        setError(undefined);
        setSelectedDocument(null);
        siteHardwareConfigurationsQuery.refetch();
        props.removeFromOpenDocuments(data?.id);
        setShowDeleteConfirmation(false);
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  const refresh = async () => {
    siteHardwareConfigurationsQuery.refetch();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-documents-found') : 'No documents found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {},
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    setSelectedDocument(null);
    refresh();
    setRefreshing(false);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const deleteDocument = async (id: string) => {
    await deleteSiteHardwareConfigurationMutation.mutateAsync({id});
  };

  useEffect(() => {
    setAllItems(siteHardwareConfigurationsQuery.data || []);
  }, [siteHardwareConfigurationsQuery.data]);

  useEffect(() => {
    props.setSelectedDocument(selectedDocument);
  }, [selectedDocument]);

  useEffect(() => {
    if (props.selectedDocument !== selectedDocument) setSelectedDocument(props.selectedDocument);
  }, [props.selectedDocument]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      <Modal
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowDeleteConfirmation(false)}
                iconName='status-stopped'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                loading={deleteSiteHardwareConfigurationMutation.isPending}
                iconName='check'
                onClick={() => deleteDocument(selectedDocument!.id)}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {bundle.getMessage('confirm')}
          </Header>
        }
        onDismiss={() => setShowDeleteConfirmation(false)}
        visible={showDeleteConfirmation}
      >
        {`${bundle.getMessage('delete')} ${selectedDocument?.name} ${selectedDocument?.versionId}`}
      </Modal>
      {error && <Alert header={bundle.getMessage('error')} type='error' > {error} </Alert>}
      <div id='DocumentsTablePanel' hidden={hideTable}>
        <Table
          {...collectionProps}
          columnDefinitions={ColumnDefinitions}
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-documents')}
              filteringPlaceholder={bundle.getMessage('find-documents')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
          }
          footer={
            <Header
              actions={
                <SpaceBetween direction='horizontal' size='s'>
                  <Button
                    onClick={event => props.createNewDocument()}
                    iconName='add-plus'
                    variant='primary'
                  >
                    {bundle.getMessage('create')}
                  </Button>
                  <Button
                    disabled={selectedDocument === null}
                    iconName='edit'
                    onClick={event => props.editDocument()}
                    variant='normal'
                  >
                    {bundle.getMessage('edit')}
                  </Button>
                  <Button
                    disabled={selectedDocument === null}
                    onClick={() => props.createNewDocumentRevision()}
                    iconName='copy'
                    variant='normal'
                  >
                    {bundle.getMessage('new-revision')}
                  </Button>
                  <Button
                    disabled={selectedDocument === null}
                    onClick={() => setShowDeleteConfirmation(true)}
                    iconName='remove'
                    variant='normal'
                  >
                    {bundle.getMessage('delete')}
                  </Button>
                </SpaceBetween>
              }
            >
            </Header>
          }
          header={
            <Header
              counter={`(${itemsCount().toString()})`}
              actions={
                <Button
                  loading={refreshing}
                  key="refreshBtn"
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                />
              }
            >
              {bundle.getMessage('documents')}
            </Header>
          }
          items={items}
          loading={siteHardwareConfigurationsQuery?.fetchStatus == 'fetching'}
          empty={siteHardwareConfigurationsQuery?.status == 'error'
            ?
              <Alert type='error'>
                `${bundle.getMessage('query-failure')} (${typeof siteHardwareConfigurationsQuery?.error === 'object'
                   ? JSON.stringify(siteHardwareConfigurationsQuery?.error)
                   : 'TODO'
                   })`
              </Alert>
            : bundle.getMessage('no-documents-found')
          }
          loadingText={bundle.getMessage('loading-documents')}
          onSelectionChange={({ detail }) => setSelectedDocument(detail.selectedItems[0]) }
          pagination={
            allItems.length > pageSize
            &&
            <Pagination
              {...paginationProps}
              ariaLabels={PaginationLabels}
            />
          }
          preferences={
            <CollectionPreferences
              onConfirm={({ detail }) => alert('TODO: handle save preferences')}
              title={bundle.getMessage('preferences')}
              confirmLabel={bundle.getMessage('confirm')}
              cancelLabel={bundle.getMessage('cancel')}
              preferences={{
                pageSize: pageSize,
              }}
              pageSizePreference={{
                title: bundle.getMessage('select-page-size'),
                options: [
                  { value: 5, label: '5' },
                  { value: 10, label: '10' },
                  { value: 15, label: '15' },
                  { value: 25, label: '25' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ],
              }}
            />
          }
          resizableColumns={true}
          selectedItems={selectedDocument ? [selectedDocument] : []}
          selectionType='single'
          trackBy='id'
        />
      </div>
    </>);
}
