import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../constants";
import { queryApplicationSettings } from "src/components/utils";

export function useSelectedApplicationSettings(selectedApplicationSettingsVersion: string) {
  return useQuery({
    enabled: selectedApplicationSettingsVersion !== null,
    queryKey: [QueryKey.selectedApplicationSettings],
    queryFn: async () => {
      if (!selectedApplicationSettingsVersion || !selectedApplicationSettingsVersion) return null;
      return(await queryApplicationSettings(selectedApplicationSettingsVersion));
    },
    retry: 3,
    staleTime: Infinity,
  });
}
