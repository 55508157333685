import { useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  FormField,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { querySites } from 'src/components/utils';
import { useQuery } from '@tanstack/react-query';
import { useBundle } from '@amzn/react-arb-tools';
import { debug } from 'src/utils';

interface ISelectSiteProps {
  selectedSiteCode: string;
  setSelectedSiteCallback: Function;
  setSiteSelectVisibleCallback: Function;
}

export default function SelectSite(props: ISelectSiteProps) {
  debug(`SelectSite(): props is ${JSON.stringify(props)}`);

  const [selected, setSelected] = useState<boolean>(false);
  const [selectedSiteCode, setSelectedSiteCode] = useState<string | undefined>(props.selectedSiteCode);

  const [bundle, isBundleLoading] = useBundle('components.common.SelectSite');

  const sitesQuery = useQuery({
    queryFn: () => querySites(),
    queryKey: ['sites'],
  });

  const siteFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedSiteCode(detail.selectedOption.label);
  };

  const selectSite = () => {
    props.setSelectedSiteCallback(selectedSiteCode);
    setSelected(true);
  };

  const siteSelectRef = useRef<SelectProps.Ref>(null);

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      onDismiss={() => props.setSiteSelectVisibleCallback(false)}
      size='small'
      visible={true}
      header={bundle.getMessage('select-a-site')}
    >
      <Container
        footer={
          <Box float='right'>
            <Button
              ariaLabel='Select User Site'
              disabled={!selectedSiteCode || selectedSiteCode === props.selectedSiteCode}
              loading={selected}
              onClick={() => selectSite()}
              variant='primary'
            >
              {bundle.getMessage('select')}
            </Button>
          </Box>
        }
      >
        <SpaceBetween size='m' direction='vertical'>
          <FormField label={bundle.getMessage('site')}>
            <Select
              ariaDescribedby={selectedSiteCode}
              ariaLabel='User Sites'
              ariaRequired
              empty={bundle.getMessage('no-sites-found')}
              filteringPlaceholder={bundle.getMessage('sites-placeholder')}
              filteringType='auto'
              loadingText={bundle.getMessage('loading-sites')}
              onChange={({ detail }) => siteFieldOnChangeHandler(detail)}
              options={sitesQuery.data?.map(s => { return { label: s.SiteCode, value: s.SiteCode }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)}
              placeholder={bundle.getMessage('select-site')}
              ref={siteSelectRef}
              renderHighlightedAriaLive={option => `Select Site ${option.label}`}
              selectedAriaLabel='Selected User Site'
              selectedOption={{ label: selectedSiteCode, value: selectedSiteCode }}
              statusType={sitesQuery.isLoading ? 'loading' : 'finished'}
            />
          </FormField>
        </SpaceBetween>
      </Container>
    </Modal>
  );
}