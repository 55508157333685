import {
  ApplicationSettings,
  DeviceInput,
  DeviceSettingsInput,
  HardwareDevice,
  HardwareType,
  Slot,
  Status,
} from 'src/API';
import {
  Button,
  Container,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { debug } from '../../utils';
import { getApplicationSettingsForHardwareTypeAndDeviceName } from './utils';
import { useAllApplicationSettings } from '../common/hooks/useAllApplicationSettings';
import { useBundle } from '@amzn/react-arb-tools';
import { useEffect, useState } from 'react';
import { useSelectedApplicationSettings } from '../common/hooks/useSelectedApplicationSettings';
import * as uuid from 'uuid';

export interface IDocumentActions {
  addDocumentItem: Function;
  undo: Function;
  close: Function;
  description: string;
  documentChanged: boolean;
  documentSaving: boolean;
  name: string;
  saveDocument: Function;
  selectedApplicationSettingsVersion: SelectProps.Option;
  setDescription: Function;
  setStatus: Function;
  setSelectedApplicationSettingsVersion: Function;
  status: string;
  updatedAt: string;
  versionId: string;
}

export function DocumentActions(props: IDocumentActions) {
  debug(`DocumentActions() props is ${JSON.stringify(props)}`);

  const allApplicationSettingsQuery = useAllApplicationSettings();

  const selectedApplicationSettingsQuery = useSelectedApplicationSettings(props.selectedApplicationSettingsVersion.value!);

  const [allApplicationSettings, setAllApplicationSettings] = useState<ApplicationSettings[] | undefined>(allApplicationSettingsQuery.data || []);
  const [description, setDescription] = useState<(string)>(props.description);
  const [hardwareTypes, setHardwareTypes] = useState<(HardwareType | null)[]>([]);
  const [hardwareDevices, setHardwareDevices] = useState<(HardwareDevice | null)[]>([]);
  const [selectedHardwareDevice, setSelectedHardwareDevice] = useState<(HardwareDevice | null)>(null);
  const [loadingHardwareDeviceTemplates, setLoadingHardwareDeviceTemplates] = useState<boolean>(false);
  const [loadingHardwareTypes, setloadingHardwareTypes] = useState<boolean>(false);
  const [selectedApplicationSettings, setSelectedApplicationSettings] = useState<ApplicationSettings | null>(selectedApplicationSettingsQuery.data || null);
  const [selectedApplicationSettingsVersion, setSelectedApplicationSettingsVersion] = useState<SelectProps.Option | null>(props.selectedApplicationSettingsVersion);
  const [selectedHardwareType, setSelectedHardwareType] = useState<SelectProps.Option | null>(null);
  const [selectedHardwareTemplate, setSelectedHardwareTemplate] = useState<SelectProps.Option | null>(null);
  const [status, setStatus] = useState<(string)>(props.status);
  const [updatedAt, setUpdatedAt] = useState<(string)>(props.updatedAt);
  const [statusOptions, setStatusOptions] = useState<(SelectProps.Options)>(() => {
    return Object.values(Status).filter(s => s !== Status.Temporary).map(value => ({ label: value, value }));
  });

  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentActions');

  const selectHardwareTemplate = (selectedOption: SelectProps.Option) => {
    debug(`DocumentActions() selectHardwareTemplate() selectedOption is ${JSON.stringify(selectedOption)}`);
    if (!selectedApplicationSettings || !selectedHardwareType?.value || !selectedOption?.value) return;
    let applicationSettingsForHardwareTypeAndDeviceName = 
        getApplicationSettingsForHardwareTypeAndDeviceName(
          selectedApplicationSettings!,
          selectedHardwareType!.value!,
          selectedOption.value!);
    if (!applicationSettingsForHardwareTypeAndDeviceName) return;
    try {
      setSelectedHardwareDevice(applicationSettingsForHardwareTypeAndDeviceName);
      setSelectedHardwareTemplate(selectedOption);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addDocumentItem = () => {
    if (!selectedApplicationSettings
      || !selectedHardwareType?.value
      || !selectedHardwareDevice) return;
    const deviceSettings: DeviceSettingsInput[] = selectedHardwareDevice?.fields
      ?.map(f => (
        {
          key: f.name,
          value: '',
          uiAttributes: f.uiAttributes,
        })) || [];
    const slots: Slot[] = selectedHardwareDevice?.slots
      ?.map(s => (
        {
          name: s.name,
          description: s.description,
          type: s.type,
          slotSettings: [],
        })) || [];
    const newDocumentItem: DeviceInput = {
      boardSettings: {
        columnSpan: 4,
        rowSpan: 3,
        sequence: undefined,
      },
      deviceSettings,
      hardwareDeviceName: selectedHardwareDevice.name,
      hardwareTypeName: selectedHardwareType.value,
      id: uuid.v4(),
      name: selectedHardwareDevice.name,
      slots,
    };
    debug(`DocumentActions() addDocumentItem() newDocumentItem is ${JSON.stringify(newDocumentItem)}`);
    props.addDocumentItem(newDocumentItem);
  };

  useEffect(() => {
    setSelectedApplicationSettingsVersion(props.selectedApplicationSettingsVersion);
  }, [props.selectedApplicationSettingsVersion]);

  useEffect(() => {
    setAllApplicationSettings(allApplicationSettingsQuery.data);
  }, [allApplicationSettingsQuery.data]);

  useEffect(() => {
    setSelectedApplicationSettings(selectedApplicationSettingsQuery.data || null);
  }, [selectedApplicationSettingsQuery.data]);

  useEffect(() => {
    setHardwareTypes(selectedApplicationSettings?.hardwareTypes || []);
    setHardwareDevices([]);
    setSelectedHardwareType(null);
    setSelectedHardwareTemplate(null);
  }, [selectedApplicationSettings]);

  useEffect(() => {
    const hardwareDevices = hardwareTypes.find(ht => ht?.name === selectedHardwareType?.value)?.hardwareDevices || [];
    setHardwareDevices(hardwareDevices);
    setSelectedHardwareTemplate(null);
  }, [selectedHardwareType]);

  // useEffect(() => {
    // setDescription(props.description);
  // }, [props.description]);

  useEffect(() => {
    props.setDescription(description);
  }, [description]);

  // useEffect(() => {
    // setStatus(props.status);
  // }, [props.status]);

  useEffect(() => {
    setUpdatedAt(props.updatedAt);
  }, [props.updatedAt]);

  // useEffect(() => {
    // props.setStatus(status);
  // }, [status]);
  
  if (isBundleLoading) return <Spinner/>;

  return (
    <Container>
      <SpaceBetween direction='vertical' size='s'>
        <Container >
          <Grid gridDefinition={[
            {colspan: 3},
            {colspan: 4},
            {colspan: 2},
            {colspan: 1},
            {colspan: 2},
          ]}>
            <FormField label={bundle.getMessage('name')} >
              <Input
                disabled
                value={props.name || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('description')} stretch >
              <Input
                onChange={( { detail }) => setDescription(detail.value)}
                value={description || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('status')} stretch >
              <Select
                onChange={event => setStatus(event.detail.selectedOption.value!)}
                selectedOption={{ label: status, value: status }}
                options={statusOptions}
              />
            </FormField>
            <FormField label={bundle.getMessage('version')} >
              <Input
                disabled
                value={props.versionId || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('updated')} >
              <Input
                disabled
                value={updatedAt || ''}
              />
            </FormField>
          </Grid>
        </Container>
        <Container>
          <SpaceBetween direction='horizontal' size='xs'>
            <FormField label={bundle.getMessage('application-settings-version')}>
              <Select
                ariaLabel={''}
                ariaDescribedby={''}
                ariaRequired
                disabled={allApplicationSettings === undefined || allApplicationSettings.length === 0}
                onChange={({ detail }) => props.setSelectedApplicationSettingsVersion(detail.selectedOption)}
                options={allApplicationSettings?.map((applicationSettings: ApplicationSettings) => {
                  return {
                    label: applicationSettings.versionId,
                    value: applicationSettings.versionId,
                  };
                })}
                renderHighlightedAriaLive={undefined}
                selectedAriaLabel=''
                selectedOption={selectedApplicationSettingsVersion}
              />
            </FormField>
            <FormField label='&#8203;'>
              <Button
                iconName='refresh'
                loading={allApplicationSettingsQuery.isFetching}
                onClick={async ()=> {
                  await allApplicationSettingsQuery.refetch();
                }}
              />
            </FormField>
            <FormField label={bundle.getMessage('hardware-type')}>
              <Select
                ariaDescribedby={''}
                ariaLabel={''}
                ariaRequired
                disabled={selectedApplicationSettingsVersion === null}
                onChange={({ detail }) => setSelectedHardwareType(detail.selectedOption)}
                options={hardwareTypes.map(ht => { return { value: ht?.name, label: ht?.name } } ) || []}
                placeholder={bundle.getMessage('choose-a-hardware-type')}
                renderHighlightedAriaLive={undefined}
                selectedAriaLabel=''
                selectedOption={selectedHardwareType}
                statusType={loadingHardwareTypes ? 'loading' : 'finished'}
              />
            </FormField>
            <FormField label={bundle.getMessage('hardware-template')}>
              <Select
                ariaLabel={''}
                ariaDescribedby={''}
                ariaRequired
                disabled={selectedHardwareType === null}
                filteringType='auto'
                onChange={({ detail }) => selectHardwareTemplate(detail.selectedOption)}
                options={hardwareDevices?.map(hd => { return { value: hd?.name, label: hd?.name } } ) || []}
                placeholder={bundle.getMessage('choose-a-hardware-template')}
                renderHighlightedAriaLive={undefined}
                selectedAriaLabel=''
                selectedOption={selectedHardwareTemplate}
                statusType={loadingHardwareDeviceTemplates ? 'loading' : 'finished'}
              />
            </FormField>
            <FormField label='&#8203;'>
              <Button
                disabled={selectedHardwareTemplate === null || props.documentSaving}
                formAction='none'
                iconName='add-plus'
                onClick={() => addDocumentItem()}
                variant='primary'
              >
                {bundle.getMessage('add')}
              </Button>
            </FormField>
            <FormField label='&#8203;'>
              <Button
                disabled={props.documentSaving}
                iconName='close'
                onClick={() => props.close()}
                variant='normal'
              >
                {bundle.getMessage('close')} 
              </Button>
            </FormField>
            <FormField label='&#8203;'>
              <Button
                disabled={!props.documentChanged || props.documentSaving}
                iconName='redo'
                onClick={() => props.undo()}
                variant='normal'
              >
                {bundle.getMessage('undo')} 
              </Button>
            </FormField>
            <FormField label='&#8203;'>
              <Button
                disabled={props.documentSaving}
                iconName='status-info'
                onClick={() => alert('TODO')}
                variant='normal'
              >
                {bundle.getMessage('verify')} 
              </Button>
            </FormField>
            <FormField label='&#8203;'>
              <Button
                disabled={!props.documentChanged || props.documentSaving}
                iconName='file'
                loading={props.documentSaving}
                onClick={() => props.saveDocument()}
                variant='primary'
              >
                {bundle.getMessage('save')}
              </Button>
            </FormField>
            <FormField label='&#8203;'>
              <Button
                disabled={props.documentChanged || props.documentSaving}
                iconName='send'
                onClick={() => alert('TODO')}
                variant='normal'
              >
                {bundle.getMessage('send')} 
              </Button>
            </FormField>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </Container>);
}
