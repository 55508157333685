import { Alert, Modal, SelectProps, Spinner } from '@amzn/awsui-components-react';
import { CreateSiteHardwareConfigurationInput, SiteHardwareConfiguration, Status } from 'src/API';
import { CreateNewDocument } from './CreateNewDocument';
import { CreateNewDocumentRevision } from './CreateNewDocumentRevision';
import { Document } from './Document';
import { DocumentsTablePanel } from './DocumentsTablePanel';
import { QueryKey } from '../common/constants';
import { createSiteHardwareConfiguration } from './utils';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSiteHardwareConfigurations } from '../common/hooks/useSiteHardwareConfigurations';

interface IDocumentsProps {
  addOpenDocument: Function;
  currentEditDocumentId: string | null;
  isAdmin: boolean;
  openDocuments: SiteHardwareConfiguration[];
  removeOpenDocument: Function;
  selectedApplicationSettingsVersion: SelectProps.Option;
  setOpenDocuments: Function;
  setSelectedApplicationSettingsVersion: Function;
  siteCode: string;
  username: string;
}

export default function Documents(props: IDocumentsProps) {
  debug(`Documents() props is ${JSON.stringify(props)}`);

  const [creatingNewDocument, setCreatingNewDocument] = useState<boolean>(false);
  const [creatingNewDocumentRevision, setCreatingNewDocumentRevision] = useState<boolean>(false);
  const [currentEditDocument, setCurrentEditDocument] = useState<SiteHardwareConfiguration | null>(null);
  const [documentSaveError, setDocumentSaveError] = useState<string | null>(null);
  const [documentSaved, setDocumentSaved] = useState<boolean>(false);
  const [documentSaving, setDocumentSaving] = useState<boolean>(false);
  const [showCreateNewDocument, setShowCreateNewDocument] = useState<boolean>(false);
  const [showCreateNewDocumentRevision, setShowCreateNewDocumentRevision] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<SiteHardwareConfiguration | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Documents.Documents');

  const queryClient = useQueryClient();

  const createSiteHardwareConfigurationMutation = useMutation(
    {
      mutationFn: createSiteHardwareConfiguration,
      onSuccess: (data) => {
        setDocumentSaved(true);
        setDocumentSaving(false);
        queryClient.fetchQuery({queryKey: [QueryKey.siteHardwareConfigurations]});
        setSelectedDocument(data);
        setShowCreateNewDocument(false);
      },
      onError: (error: any) => {
        setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
        setDocumentSaving(false);
      },
    }
  );

  const createNewDocument = async (newDocumentProps: {name: string, description: string, versionId: string}) => {
    setCreatingNewDocument(true);
    setSelectedDocument(null);
    try {
      const newDocument: CreateSiteHardwareConfigurationInput = {
        name: newDocumentProps.name,
        versionId: newDocumentProps.versionId,
        description: newDocumentProps.description,
        status: Status.Development,
        siteCode: props.siteCode!,
        devices: [],
      };
      await createSiteHardwareConfigurationMutation.mutateAsync(newDocument);
      setShowCreateNewDocument(false);
    } catch(error) {
      console.error(error);
      setShowCreateNewDocument(false);
      setDocumentSaveError(JSON.stringify(error));
    }
    setCreatingNewDocument(false);
  };

  const siteHardwareConfigurationsQuery = useSiteHardwareConfigurations(props.siteCode, '');

  const createNewDocumentRevision = async (versionId: string) => {
    setCreatingNewDocumentRevision(true);
    if (!selectedDocument) return;
    try {
      const newDocument: CreateSiteHardwareConfigurationInput = {
        name: selectedDocument.name,
        versionId: versionId,
        description: selectedDocument.description,
        status: Status.Development,
        siteCode: props.siteCode!,
        devices: selectedDocument.devices,
      };
      await createSiteHardwareConfigurationMutation.mutateAsync(newDocument);
      setShowCreateNewDocumentRevision(false);
    } catch(error) {
      console.error(error);
      setShowCreateNewDocumentRevision(false);
      setDocumentSaveError(JSON.stringify(error));
    }
    setCreatingNewDocumentRevision(false);
  };

  const edit = () => {
    setCurrentEditDocument(selectedDocument);
    props.addOpenDocument(selectedDocument);
  };

  const updateCurrentEditDocument = (updatedDocument: SiteHardwareConfiguration) => {
    debug(`Documents() updateCurrentEditDocument updatedDocument is ${JSON.stringify(updatedDocument)}`);
    setCurrentEditDocument(updatedDocument);
    const newOpenDocuments = props.openDocuments.filter(d => d.id !== updatedDocument.id);
    newOpenDocuments.push(updatedDocument);
    props.setOpenDocuments(newOpenDocuments);
  };

  const removeFromOpenDocuments = (documentId: string) => {
    debug(`Documents() removeFromOpenDocuments document is ${JSON.stringify(document)}`);
    const newOpenDocuments = props.openDocuments.filter(d => d.id !== documentId);
    props.setOpenDocuments(newOpenDocuments);
  };

  const close = () => {
    setCurrentEditDocument(null);
    setSelectedDocument(null);
    props.removeOpenDocument(selectedDocument);
  };

  useEffect(() => {
    if (props.currentEditDocumentId) {
      const document = props.openDocuments.find((od) => od.id === props.currentEditDocumentId);
      if (document) {
        setSelectedDocument(document);
        setCurrentEditDocument(document);
      }
    }
    if (!props.currentEditDocumentId) {
      setCurrentEditDocument(null);
    }
  }, [props.currentEditDocumentId]);

  useEffect(() => {
    if (props.siteCode != '') siteHardwareConfigurationsQuery.refetch();
  }, [props.siteCode]);
  
  if (isBundleLoading) return <Spinner/>;

  if (props.siteCode === '') {
    return (
      <Alert
        type='info'
      >
        {bundle.getMessage('select-a-site')}
      </Alert>
    );
  }

  if (currentEditDocument) {
    return(
      <Document
        close={close}
        document={currentEditDocument}
        selectedApplicationSettingsVersion={props.selectedApplicationSettingsVersion}
        setSelectedApplicationSettingsVersion={props.setSelectedApplicationSettingsVersion}
        siteCode={props.siteCode}
        updateCurrentEditDocument={updateCurrentEditDocument}
      />);
  }

  return(
    <>
      {documentSaveError
      &&
      <Alert
        onDismiss={() => setDocumentSaveError(null)}
        type='error'
      >
        {documentSaveError}
      </Alert>}
      <Modal
        header={<h3>{bundle.getMessage('create')}</h3>}
        onDismiss={() => setShowCreateNewDocument(false)}  
        size='small'
        visible={showCreateNewDocument}
      >
        <CreateNewDocument
          cancel={() => setShowCreateNewDocument(false)}
          createDocument={createNewDocument}
          creatingDocument={creatingNewDocument}
        />
      </Modal>
      <Modal
        header={<h3>{bundle.getMessage('create')}</h3>}
        onDismiss={() => setShowCreateNewDocumentRevision(false)}  
        size='small'
        visible={showCreateNewDocumentRevision}
      >
        <CreateNewDocumentRevision
          cancel={() => setShowCreateNewDocumentRevision(false)}
          createDocumentRevision={createNewDocumentRevision}
          creatingDocumentRevision={creatingNewDocumentRevision}
        />
      </Modal>
      <DocumentsTablePanel
        admin={props.isAdmin}
        createNewDocument={() => setShowCreateNewDocument(true)}
        createNewDocumentRevision={() => setShowCreateNewDocumentRevision(true)}
        editDocument={() => edit()}
        selectedDocument={selectedDocument}
        removeFromOpenDocuments={removeFromOpenDocuments}
        setSelectedDocument={setSelectedDocument}
        siteCode={props.siteCode}
        siteHardwareConfigurationsQuery={siteHardwareConfigurationsQuery}
      />
    </>);
}
