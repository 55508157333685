import { debugLogging } from 'src/components/App';

export function debug(message: string) {
  debugLogging && console.debug(message);
}

export function getTimeString(timestamp: number) {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}
