import {
  ComponentType,
  UIAttribute,
} from '../common/constants';
import {
  Button,
  Container,
  ExpandableSection,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Spinner,
  Toggle,
} from '@amzn/awsui-components-react';
import {
  Device,
  DeviceSettings,
} from 'src/API';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useEffect, useState } from 'react';
import { BoardItem } from '@amzn/awsui-board-components';

export interface IDocumentBoardItemProps {
  documentBoardItem: Device;
  documentItemRemove: Function;
  documentItemSave: Function;
  documentItemSaving: boolean;
  documentItemUndoChanges: Function;
  setDocumentBoardItem: Function;
};

export function DocumentBoardItem(props: IDocumentBoardItemProps) {
  debug(`DocumentBoardItem() props is ${JSON.stringify(props)}`);

  const [documentBoardItem, setDocumentBoardItem] = useState<Device>(props.documentBoardItem);
  const [documentBoardItemChanged, setDocumentBoardItemChanged] = useState<boolean>(false);
  const [expandSlots, setExpandSlots] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentBoardItem');

  if (isBundleLoading) return <Spinner/>;

  const createHardwareDeviceForm = () => {
    debug(`DocumentItem() createHardwareDeviceForm() documentBoardItem is ${JSON.stringify(documentBoardItem)}`);
    return(
      <BoardItem
        header={
          <Header
            actions={
              <SpaceBetween
                direction='horizontal'
                size='s'
              >
                <Button
                  disabled={!documentBoardItemChanged}
                  iconAlt={bundle.getMessage('undo')}
                  iconName='redo'
                  onClick={() => props.documentItemUndoChanges(documentBoardItem)}
                  variant='inline-icon'
                />
                <Button
                  iconAlt={bundle.getMessage('remove')}
                  iconName='remove'
                  onClick={() => props.documentItemRemove(documentBoardItem.id)}
                  variant='inline-icon'
                />
                <Button
                  iconAlt={bundle.getMessage('verify')}
                  iconName='status-info'
                  onClick={() => alert('TODO: PACS configuration verification')}
                  variant='inline-icon'
                />
                <Button
                  disabled={!documentBoardItemChanged}
                  iconAlt={bundle.getMessage('save')}
                  iconName='check'
                  loading={props.documentItemSaving}
                  onClick={() => {
                    props.documentItemSave(documentBoardItem);
                    setDocumentBoardItemChanged(false);
                  }}
                  variant='inline-icon'
                />
                <Button
                  iconAlt={bundle.getMessage('send')}
                  iconName='send'
                  loading={false}
                  onClick={() => alert('TODO: send to PACS')}
                  variant='inline-icon'
                />
              </SpaceBetween>}
          >
            {documentBoardItem.name}
          </Header>}
        i18nStrings={{
          dragHandleAriaLabel: 'Drag handle',
          dragHandleAriaDescription: 'Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.',
          resizeHandleAriaLabel: 'Resize handle',
          resizeHandleAriaDescription: 'Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard.'
        }}
      >
      <form onSubmit={e => e.preventDefault()}>
        <Form>
          <Container>
            <SpaceBetween direction='horizontal' size='s'>
              {documentBoardItem?.deviceSettings
                ?.sort((dsA: any, dsB: any): number => {
                  if (!dsA || !dsB) return 0;
                  const sequenceA = parseInt(dsA?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  const sequenceB = parseInt(dsB?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  return sequenceA > sequenceB ? 1 : -1;
              })
              .map((ds: (DeviceSettings | null)) => {
                if (ds === null) return(<></>);
                const componentType = ds.uiAttributes?.find((a: any) => a?.name === UIAttribute.componentType)?.value || ComponentType.input;
                return(
                  <FormField
                    key={ds?.key}
                    label={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.label)?.value || ds?.key}
                  >
                    {componentType === ComponentType.input
                    ? 
                      <Input
                        disabled={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly) ?.value === 'true' || false}
                        onChange={({ detail }) => {
                          const deviceSettings = documentBoardItem?.deviceSettings.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds?.key,
                            uiAttributes: ds?.uiAttributes,
                            value: detail.value,
                          };
                          const newDocumentBoardItem: Device = {
                            ...documentBoardItem,
                            deviceSettings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setDocumentBoardItem(newDocumentBoardItem);
                          setDocumentBoardItemChanged(true);
                          props.setDocumentBoardItem(newDocumentBoardItem);
                        }}
                        value={ds.value || ''}
                      />
                    :
                      <></>}
                    {componentType === ComponentType.toggle
                    ? 
                      <Toggle
                        disabled={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly)
                          ?.value === 'true' || false}
                        onChange={({ detail }) => {
                          const deviceSettings = documentBoardItem?.deviceSettings.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds?.key,
                            uiAttributes: ds?.uiAttributes,
                            value: detail.checked.toString(),
                          };
                          const newDocumentBoardItem: Device = {
                            ...documentBoardItem,
                            deviceSettings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setDocumentBoardItem(newDocumentBoardItem);
                          setDocumentBoardItemChanged(true);
                          props.setDocumentBoardItem(newDocumentBoardItem);
                        }}
                        checked={ds.value === 'true' || false}
                      />
                    :
                      <></>}
                  </FormField>);
                })}
            </SpaceBetween>
            {documentBoardItem.slots
            && documentBoardItem.slots?.length > 0
            &&
            <>
              <br/>
              <Container>
                <ExpandableSection
                  expanded={expandSlots}
                  headerText='Slots'
                  onChange={() => setExpandSlots(!expandSlots)}
                >
                  {documentBoardItem.slots.map(s =>
                    <ExpandableSection
                      key={`${documentBoardItem.id}-${s?.name}`}
                      headerText={s?.name}
                    >
                      {s?.description} {s?.type}
                    </ExpandableSection>)}
                </ExpandableSection>
              </Container>
            </>}
          </Container>
        </Form>
      </form>
      </BoardItem>
    );
  };

  return(
    <>
      {createHardwareDeviceForm()}
    </>);
}