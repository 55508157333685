import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../constants";
import { queryAllApplicationSettings } from "src/components/utils";

export function useAllApplicationSettings() {
  return useQuery({
    queryKey: [QueryKey.allApplicationSettings],
    queryFn: async () => {
      return(await queryAllApplicationSettings());
    },
    retry: 3,
    staleTime: Infinity,
  });
}
